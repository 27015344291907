<sans-identity-template [title]="title">
  <form [formGroup]="signInForm" (ngSubmit)="onSubmit()" class="sign-in-form">
    <mat-form-field class="form-field">
      <mat-label>{{ emailOrUsernameLabel }}</mat-label>
      <input matInput type="text" formControlName="emailOrUsername" />
    </mat-form-field>

    <mat-form-field class="form-field">
      <mat-label>{{ passwordLabel }}</mat-label>
      <input type="password" matInput formControlName="password" />
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="submit-button"
    >
      {{ submitLabel }}
    </button>
  </form>

  <div>
    <a mat-button routerLink="/request-password-reset">{{
      requestResetPasswordLabel
    }}</a>
  </div>

  <span>
    {{ signUpLabel }}
    <a mat-button routerLink="/sign-up">{{ signUpActionLabel }}</a>
  </span>
</sans-identity-template>
