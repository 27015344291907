import { CommandApiResponse } from '../../../core/services/models/command-api.response'
import { SetNameErrorCode } from '../../error-codes/set-name.error-code'
import { CommandApiResponseData } from '../../../core/services/models/command-api.response-data'

export class SetNameResponse extends CommandApiResponse<SetNameErrorCode> {
  private constructor() {
    super()
  }

  static fromApiResponse(
    commandApiResponseData: CommandApiResponseData<SetNameErrorCode>,
  ): SetNameResponse {
    const response = new SetNameResponse()
    response._errorCode = commandApiResponseData.errorCode
    response._errorCodes = commandApiResponseData.errorCodes
    response._isSuccessful = commandApiResponseData.isSuccessful

    return response
  }

  getErrorDescriptions(): string[] {
    return this.errorCodes.map((errorCode) =>
      this.getErrorDescription(errorCode),
    )
  }

  private getErrorDescription(errorCode: SetNameErrorCode): string {
    switch (errorCode) {
      case 'Unsuccessful':
        return $localize`:Set name error code|Unsuccessful:Unable to save first name`
    }
  }
}
