import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { SignUpResponse } from '../../responses/sign-up-response/sign-up.response'

@Component({
  selector: 'sans-sign-up-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  templateUrl: './sign-up-dialog.component.html',
  styleUrls: ['./sign-up-dialog.component.scss'],
})
export class SignUpDialogComponent {
  successfulTitle = $localize`:Title|Successful sign up dialog title:Account created. Please check your inbox to confirm your email`
  notSuccessfulTitle = $localize`:Title|Not successful sign up dialog title:Oops something went wrong`
  closeLabel = $localize`:Label|Close label:Close`

  isSuccessful = this.signUpResponse.isSuccessful
  errorDescriptions = this.signUpResponse.getErrorDescriptions()

  constructor(
    private dialogRef: MatDialogRef<SignUpDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private signUpResponse: SignUpResponse,
  ) {}

  onClose() {
    this.dialogRef.close()
  }
}
