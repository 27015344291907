import { ViewGame } from './view-game'
import { GameSettingsNull } from './game.settings.null'
import { ViewGamePlayerNull } from './view-game-player.null'

export class ViewGameNull extends ViewGame {
  constructor() {
    super({
      gameShortId: '',
      viewGameStatus: 'Waiting',
      gameSettings: new GameSettingsNull(),
      players: [
        new ViewGamePlayerNull('Center', true),
        new ViewGamePlayerNull('Right', false),
        new ViewGamePlayerNull('Left', false),
      ],
      isUserCreator: false,
      canUserCancel: false,
      createdAt: new Date(),
    })
  }
}
