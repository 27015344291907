import { ViewGamePlayer } from './view-game-player'
import { SitPosition } from '../types/sit-position'

export class ViewGamePlayerNull extends ViewGamePlayer {
  constructor(sitPosition: SitPosition, isCreator: boolean) {
    super({
      username: '',
      sitPosition: sitPosition,
      hasSat: false,
      isCreator: isCreator,
      isInvited: false,
      canUserSitAtGame: false,
      canUserLeaveGame: false,
      canUserAcceptInvite: false,
    })
  }
}
