import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { CreateGameSetupResponse } from '../../responses/create-game-setup.response'
import { USERNAME_NOT_SET_LABEL } from '../../../../core/providers/username-not-set-label/username-not-set-label.provider'
import { MatButtonModule } from '@angular/material/button'
import { NgForOf } from '@angular/common'

@Component({
  selector: 'sans-create-game-setup-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    NgForOf,
  ],
  templateUrl: './create-game-setup-dialog.component.html',
  styleUrl: './create-game-setup-dialog.component.scss',
})
export class CreateGameSetupDialogComponent {
  title = $localize`:Title|Set username dialog title:Oops something went wrong`
  closeLabel = $localize`:Label|Close label:Close`
  errorDescriptions = this.createGameSetupResponse.getErrorDescriptions(
    this.usernameNotSetLabel,
  )

  constructor(
    private dialogRef: MatDialogRef<CreateGameSetupDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private createGameSetupResponse: CreateGameSetupResponse,
    @Inject(USERNAME_NOT_SET_LABEL) private usernameNotSetLabel: string,
  ) {}

  onClose() {
    this.dialogRef.close()
  }
}
