import { Component, OnDestroy, OnInit } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { Router, RouterLink } from '@angular/router'
import { MatButtonModule } from '@angular/material/button'
import { LogoAssets } from '../../../../core/providers/logo-assets/logo-assets.provider'
import { CreateGameSetupContent } from '../../contents/create-game-setup.content'
import { CreateGameSetupErrorCodeProvider } from '../../error-codes/error-status-provider/create-game-setup-error-code.provider'
import { Subscription } from 'rxjs'
import { UserService } from '../../../../identity/services/user.service'

@Component({
  selector: 'sans-create-game-setup-error',
  standalone: true,
  imports: [CommonModule, MatButtonModule, NgOptimizedImage, RouterLink],
  templateUrl: './create-game-setup-error.component.html',
  styleUrls: ['./create-game-setup-error.component.scss'],
})
export class CreateGameSetupErrorComponent implements OnInit, OnDestroy {
  private userSubscription = new Subscription()

  createGameSetupErrorCode =
    this.unableToCreateGameSetupContextProvider.getErrorCode()

  title = $localize`:Page title|UnableToCreateGameSetupComponent:Unable to create game`

  description = this.createGameSetupContent.getErrorCodeDescription(
    this.createGameSetupErrorCode,
  )

  viewGamesButtonLabel = $localize`:Button label|UnableToCreateGameSetupComponent:View games`

  constructor(
    public logoAssets: LogoAssets,
    private unableToCreateGameSetupContextProvider: CreateGameSetupErrorCodeProvider,
    private router: Router,
    private createGameSetupContent: CreateGameSetupContent,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.userService.user$.subscribe(async (user) => {
      if (
        this.createGameSetupErrorCode === 'UsernameNotSet' &&
        user.isUsernameSet
      ) {
        await this.router.navigate(['create-game'], {
          onSameUrlNavigation: 'reload',
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe()
  }

  async onViewGamesSelected(): Promise<void> {
    await this.router.navigate(['view-games'])
  }
}
