import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { GamePlayConfigs } from '../configs/game-play.configs'

@Injectable()
export class GamePlayService {
  private readonly _hasGameInProgress$ = new BehaviorSubject<boolean>(false)
  readonly hasGameInProgress$ = this._hasGameInProgress$.asObservable()

  constructor(
    private httpClient: HttpClient,
    private gamePlayConfigs: GamePlayConfigs,
  ) {}

  async hasGameInProgress(): Promise<boolean> {
    const hasGameInProgress = await firstValueFrom(
      this.httpClient.get<boolean>(
        `${this.gamePlayConfigs.siteApiUrl}/has-game-in-progress`,
        {
          withCredentials: true,
        },
      ),
    )

    this._hasGameInProgress$.next(hasGameInProgress)
    return hasGameInProgress
  }
}
