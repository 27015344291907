import { InjectionToken, Provider } from '@angular/core'
import { environment } from '../../../environments/environment'

export const PASSWORD_REGEX = new InjectionToken<RegExp>('Password regex')

export const providePasswordRegEx: Provider = [
  {
    provide: PASSWORD_REGEX,
    useFactory: (): RegExp => {
      if (environment.name === 'local') {
        return new RegExp(`^[^ \\n\\r]{3,}$`)
      }
      return new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\`~!@#$%^&*()=+[\\]{}\\\\|,.<>/?;:\'"-])[A-Za-z\\d\`~!@#$%^&*()=+[\\]{}\\\\|,.<>/?;:\'"-]{8,}$`)
    },
  },
]
