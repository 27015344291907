import { InjectionToken, Provider } from '@angular/core'

export const USERNAME_NOT_SET_LABEL = new InjectionToken<string>(
  'Username not set label',
)

export const provideUsernameNotSetLabel: Provider = [
  {
    provide: USERNAME_NOT_SET_LABEL,
    useValue: $localize`:Username not set|Username not set label:You must set username in order to create or join games`,
  },
]
