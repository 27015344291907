import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UserService } from '../../services/user.service'
import { Router } from '@angular/router'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { ConfirmEmailDialogComponent } from '../confirm-email-dialog/confirm-email-dialog.component'

@Component({
  selector: 'sans-confirm-email',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit {
  constructor(
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  async ngOnInit(): Promise<void> {
    const response = await this.userService.confirmEmail()

    if (response.isSuccessful) {
      await this.router.navigate(['/sign-in'])
    } else {
      await this.router.navigate(['/'])
    }

    this.dialog.open(ConfirmEmailDialogComponent, { data: response })
  }
}
