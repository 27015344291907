<div class="container">
  <a routerLink="/" class="logo-img">
    <img
      [ngSrc]="logoAssets.imgSrc"
      [width]="50"
      [height]="50"
      [alt]="logoAssets.altImg"
      priority="high"
    />
  </a>

  <h1 class="title">{{title}}</h1>

  <button
    mat-raised-button
    color="primary"
    (click)="onHomeSelected()"
  >
    {{homeButtonLabel}}
  </button>
</div>
