import { Component } from '@angular/core'
import { IdentityTemplateComponent } from '../identity-template/identity-template.component'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { UserService } from '../../services/user.service'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { RequestPasswordResetDialogComponent } from '../request-password-reset-dialog/request-password-reset-dialog.component'
import { WindowService } from '../../../core/services/window/window.service'

@Component({
  selector: 'sans-request-password-reset',
  standalone: true,
  imports: [
    IdentityTemplateComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './request-password-reset.component.html',
  styleUrl: './request-password-reset.component.scss',
})
export class RequestPasswordResetComponent {
  private email = this.windowService.findQueryParameter('email') ?? ''

  title = $localize`:Title|Request password reset title:Request password reset`
  emailLabel = $localize`:Label|Email label:Email`
  submitLabel = $localize`:Submit|Request password reset submit label:Request password reset`

  requestPasswordResetForm = this.formBuilder.group({
    email: [`${this.email}`, [Validators.required, Validators.email]],
  })

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private windowService: WindowService,
  ) {}

  async onSubmit(): Promise<void> {
    if (!this.requestPasswordResetForm.valid) {
      return
    }

    const response = await this.userService.requestPasswordReset(
      this.requestPasswordResetForm.controls.email.value!,
    )

    if (response.isSuccessful) {
      await this.router.navigate(['/'])
    }

    this.dialog.open(RequestPasswordResetDialogComponent, { data: response })
  }
}
