import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AlreadySignedInComponent } from '../already-signed-in/already-signed-in.component'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { UserService } from '../../services/user.service'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { Router, RouterLink } from '@angular/router'
import { MatIconModule } from '@angular/material/icon'
import { PASSWORD_REGEX } from '../../providers/password-regex.provider'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { SignUpDialogComponent } from '../sign-up-dialog/sign-up-dialog.component'
import { IdentityTemplateComponent } from '../identity-template/identity-template.component'
import { PasswordInputComponent } from "../../../core/components/password-input/password-input.component";

@Component({
  selector: 'sans-sign-up',
  standalone: true,
  imports: [
    CommonModule,
    AlreadySignedInComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterLink,
    MatIconModule,
    MatDialogModule,
    IdentityTemplateComponent,
    PasswordInputComponent
  ],
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent {
  title = $localize`:Page title|Sign up page title:Sign up to Game Sans`
  emailLabel = $localize`:Label|Email label:Email`
  passwordLabel = $localize`:Label|Password label:Password`
  submitLabel = $localize`:Label|Sign up submit label:Sign up`

  signInLabel = $localize`:Label|Sign in label on sign up:Already have an account?`
  signInActionLabel = $localize`:Label|Sign in action label on sign up:Sign in`

  signUpForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.pattern(this.passwordRegEx)]],
  })

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    @Inject(PASSWORD_REGEX) private passwordRegEx: RegExp,
    private dialog: MatDialog,
  ) {}

  async onSubmit(): Promise<void> {
    if (!this.signUpForm.valid) {
      return
    }

    const response = await this.userService.signUp(
      this.signUpForm.controls.email.value!,
      this.signUpForm.controls.password.value!,
    )

    if (response.isSuccessful) {
      await this.router.navigate(['/sign-in'])
    }

    this.dialog.open(SignUpDialogComponent, { data: response })
  }
}
