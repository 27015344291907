<mat-form-field
  class="form-field"
  [ngClass]="isMandatory ? 'mandatory' : ''"
  subscriptSizing="dynamic"
>
  <mat-label>{{ inputLabel }}</mat-label>
  <input
    class="editable-input"
    [readonly]="isReadonly"
    matInput
    [value]="inputValue"
    #usernameInput
  />

  <button
    *ngIf="isReadonly"
    mat-icon-button
    [attr.aria-label]="editIconAriaLabel"
    class="icon"
    (click)="onEditInputFieldSelected()"
    type="button"
    [matTooltip]="editTooltipLabel"
    [matTooltipShowDelay]="tooltipShowDelay"
  >
    <mat-icon>edit</mat-icon>
  </button>

  <button
    *ngIf="!isReadonly"
    mat-icon-button
    [attr.aria-label]="saveIconAriaLabel"
    class="icon save-icon"
    (click)="onSaveInputFieldSelected(usernameInput.value)"
    type="button"
    [matTooltip]="saveTooltipLabel"
    [matTooltipShowDelay]="tooltipShowDelay"
  >
    <mat-icon>save</mat-icon>
  </button>

  <button
    *ngIf="!isReadonly"
    mat-icon-button
    [attr.aria-label]="cancelEditIconAriaLabel"
    class="icon"
    (click)="onCancelEditSelected()"
    type="button"
    [matTooltip]="cancelEditTooltipLabel"
    [matTooltipShowDelay]="tooltipShowDelay"
  >
    <mat-icon>cancel</mat-icon>
  </button>
</mat-form-field>
