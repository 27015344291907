import { Component, Inject } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { NgForOf } from '@angular/common'
import { SetNameResponse } from '../../responses/set-name-response/set-name.response'

@Component({
  selector: 'sans-set-name-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    NgForOf,
  ],
  templateUrl: './set-name-dialog.component.html',
  styleUrl: './set-name-dialog.component.scss',
})
export class SetNameDialogComponent {
  title = $localize`:Title|Set name dialog title:Oops something went wrong`
  closeLabel = $localize`:Label|Close label:Close`
  errorDescriptions = this.setNameResponse.getErrorDescriptions()

  constructor(
    private dialogRef: MatDialogRef<SetNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private setNameResponse: SetNameResponse,
  ) {}

  onClose() {
    this.dialogRef.close()
  }
}
