import { Component } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { MatButton } from '@angular/material/button'
import { Router, RouterLink } from '@angular/router'
import { LogoAssets } from '../../providers/logo-assets/logo-assets.provider'

@Component({
  selector: 'sans-page-not-found',
  standalone: true,
  imports: [CommonModule, MatButton, NgOptimizedImage, RouterLink],
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  title = $localize`:Title|Page not found title:Page not found`
  homeButtonLabel = $localize`:Button label|Page not found home button label:Home`

  constructor(
    public logoAssets: LogoAssets,
    private router: Router,
  ) {}

  async onHomeSelected(): Promise<void> {
    await this.router.navigate(['/'])
  }
}
