import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { SetUsernameResponse } from '../../responses/set-username-response/set-username.response'
import { MatButtonModule } from '@angular/material/button'
import { NgForOf, NgIf } from '@angular/common'

@Component({
  selector: 'sans-set-username-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    NgForOf,
    NgIf,
  ],
  templateUrl: './set-username-dialog.component.html',
  styleUrl: './set-username-dialog.component.scss',
})
export class SetUsernameDialogComponent {
  title = $localize`:Title|Set username dialog title:Oops something went wrong`
  closeLabel = $localize`:Label|Close label:Close`
  errorDescriptions = this.setUsernameResponse.getErrorDescriptions()

  constructor(
    private dialogRef: MatDialogRef<SetUsernameDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private setUsernameResponse: SetUsernameResponse,
  ) {}

  onClose() {
    this.dialogRef.close()
  }
}
