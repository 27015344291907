import { Component } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { MatButton } from '@angular/material/button'
import { Router, RouterLink } from '@angular/router'
import { LogoAssets } from '../core/providers/logo-assets/logo-assets.provider'

@Component({
  selector: 'sans-learn-preferans',
  standalone: true,
  imports: [CommonModule, MatButton, NgOptimizedImage, RouterLink],
  templateUrl: './learn-preferans.component.html',
  styleUrls: ['./learn-preferans.component.scss'],
})
export class LearnPreferansComponent {
  title = $localize`:Title|Learn preferans page title:Coming soon...`
  homeButtonLabel = $localize`:Button label|Page not found home button label:Home`

  constructor(
    public logoAssets: LogoAssets,
    private router: Router,
  ) {}

  async onHomeSelected(): Promise<void> {
    await this.router.navigate(['/'])
  }
}
