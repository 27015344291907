import { Injectable } from '@angular/core'
import { DocumentService } from '../document/document.service'
import { StorageService } from '../storages/storage.service'
import { BehaviorSubject } from 'rxjs'

export type ThemeType = 'Light' | 'Dark'

@Injectable({ providedIn: 'root' })
export class ThemeService {
  static readonly LOCAL_STORAGE_KEY = 'ThemeType'
  static readonly LIGHT_THEME_CLASS = 'light-theme'
  static readonly DARK_THEME_CLASS = 'dark-theme'

  private readonly _theme$ = new BehaviorSubject<ThemeType>('Light')
  readonly theme$ = this._theme$.asObservable()

  constructor(
    private documentService: DocumentService,
    private storageService: StorageService,
  ) {}

  toggleTheme(): void {
    const theme = this._theme$.value === 'Light' ? 'Dark' : 'Light'
    this.setTheme(theme)
  }

  setTheme(theme: ThemeType): void {
    this.documentService.removeClassFromBody(ThemeService.LIGHT_THEME_CLASS)
    this.documentService.removeClassFromBody(ThemeService.DARK_THEME_CLASS)

    if (theme === 'Light') {
      this.documentService.addClassToBody(ThemeService.LIGHT_THEME_CLASS)
    } else {
      this.documentService.addClassToBody(ThemeService.DARK_THEME_CLASS)
    }

    this.storageService.setItemStr(ThemeService.LOCAL_STORAGE_KEY, theme)
    this._theme$.next(theme)
  }
}
