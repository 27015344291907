<div class="container">
  <div class="content">
    <img
      [ngSrc]="logoAssets.imgSrc"
      [width]="200"
      [height]="200"
      [alt]="logoAssets.altImg"
      priority="high"
    />
    <div class="description">
      <div class="mat-headline-3">
        {{ appName }}
      </div>
      <div class="mat-headline-5">
        {{ tagline }}
      </div>
      <div class="nav-links">
        <a
          routerLink="/create-game"
          color="primary"
          mat-raised-button
          class="nav-link"
        >
          {{ createGameButtonLabel }}
        </a>
        <a
          routerLink="/learn-preferans"
          color="primary"
          mat-raised-button
          class="nav-link"
        >
          {{ learnPreferansButtonLabel }}
        </a>
      </div>
    </div>
  </div>
</div>
