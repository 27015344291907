import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatOptionModule } from '@angular/material/core'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { SitPosition } from '../../../view-games/types/sit-position'
import { debounceTime, map, Subscription, switchMap } from 'rxjs'
import { CreateGameSetupService } from '../../services/create-game-setup.service'
import { FilteredPlayer } from '../../models/filtered-player'
import { FilterPlayersErrorProvider } from '../../error-codes/filter-players/filter-players-error.provider'

@Component({
  selector: 'sans-player-input-field',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    ReactiveFormsModule,
  ],
  templateUrl: './player-input-field.component.html',
  styleUrls: ['./player-input-field.component.scss'],
})
export class PlayerInputFieldComponent implements OnInit, OnDestroy {
  private _valueChangesSubscription!: Subscription
  private _statusChangesSubscription!: Subscription
  private _searchPlayerHint = $localize`:Input hint|Search player input hint:Start typing to search for a player`

  @Input() createGameSetupForm!: FormGroup
  @Input() playerFormControl: FormControl = new FormControl('')
  @Input() playerControlName = ''
  @Input() sitPosition!: SitPosition
  @Input() otherPlayerFormControl: FormControl = new FormControl('')
  @Output() filteredPlayers = new EventEmitter<FilteredPlayer[]>()

  playerLabel = ''
  playerPlaceholder = ''

  searchPlayerHint = this._searchPlayerHint
  playerNotFoundError = $localize`:Input error|Player not found input error:Player not found`

  filteredPlayerOptions: FilteredPlayer[] = []
  playerError = ''

  constructor(
    private createGameSetupService: CreateGameSetupService,
    private filterPlayersErrorProvider: FilterPlayersErrorProvider,
  ) {}

  ngOnInit(): void {
    this.playerLabel =
      this.sitPosition === 'Left'
        ? $localize`:Input label|Left player label:Left player`
        : $localize`:Input label|Right player label:Right player`

    this.playerPlaceholder = this.sitPosition
      ? $localize`:Input placeholder|Left player placeholder:Left player`
      : $localize`:Input placeholder|Right player placeholder:Right player`

    this._valueChangesSubscription = this.getValueChangesSubscription()
    this._statusChangesSubscription = this.getStatusChangesSubscription()
  }

  ngOnDestroy(): void {
    this._valueChangesSubscription.unsubscribe()
    this._statusChangesSubscription.unsubscribe()
  }

  private getValueChangesSubscription(): Subscription {
    return this.playerFormControl.valueChanges
      .pipe(
        map((formControlValue): string => {
          if (formControlValue) {
            this.searchPlayerHint = ''
            return formControlValue
          }

          this.searchPlayerHint = this._searchPlayerHint
          return ''
        }),
        debounceTime(300),
        switchMap(async (usernameFilter): Promise<void> => {
          if (!usernameFilter) {
            this.filteredPlayerOptions = []
            this.filteredPlayers.emit([])
            return
          }

          const isUsernameFound = this.filteredPlayerOptions.find(
            (filteredPlayer) => filteredPlayer.username === usernameFilter,
          )

          if (isUsernameFound) {
            this.filteredPlayerOptions = []
            return
          }

          const filteredPlayerOptions =
            await this.createGameSetupService.filterPlayers(usernameFilter)

          this.filteredPlayerOptions = filteredPlayerOptions.filter(
            (option) => option.username !== this.otherPlayerFormControl.value,
          )

          this.filteredPlayers.emit(filteredPlayerOptions)
        }),
      )
      .subscribe()
  }

  private getStatusChangesSubscription(): Subscription {
    return this.playerFormControl.statusChanges
      .pipe(
        map((controlStatus): void => {
          if (controlStatus === 'VALID') {
            this.playerError = ''
            return
          }

          if (
            this.playerFormControl.getError(
              this.filterPlayersErrorProvider.mapToErrorCodeStr(
                'PlayerUsernameNotFound',
              ),
            )
          ) {
            this.playerError = this.playerNotFoundError
          }
        }),
      )
      .subscribe()
  }
}
