import { InjectionToken, Provider } from '@angular/core'

export const APP_NAME = new InjectionToken<string>('App name')

export const provideAppName: Provider = [
  {
    provide: APP_NAME,
    useValue: $localize`:App name:Game Sans`,
  },
]
