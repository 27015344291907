<mat-form-field [formGroup]="formGroup" class="form-field">
  <mat-label>{{ passwordLabel }}</mat-label>
  <input
    [type]="passwordInputType"
    matInput
    [formControlName]="passwordFormControlName"
    class="input-field"
  />
  <button
    mat-icon-button
    [attr.aria-label]="visibilityIconAriaLabel"
    class="visibility-icon"
    (click)="onVisibilityIconSelected()"
    type="button"
    [matTooltip]="visibilityIconAriaLabel"
    [matTooltipShowDelay]="tooltipShowDelay"
  >
    <mat-icon>{{ visibilityIconName }}</mat-icon>
  </button>
</mat-form-field>
