<sans-identity-template [title]="title">
  <form [formGroup]="requestPasswordResetForm" (ngSubmit)="onSubmit()" class="form">
    <mat-form-field class="form-field">
      <mat-label>{{emailLabel}}</mat-label>
      <input matInput type="text" formControlName="email" />
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="submit-button"
    >
      {{ submitLabel }}
    </button>
  </form>
</sans-identity-template>
