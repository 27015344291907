import { Component, Inject } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { RouterLink } from '@angular/router'
import { APP_NAME } from '../core/providers/app-name/app-name.provider'
import { LogoAssets } from '../core/providers/logo-assets/logo-assets.provider'

@Component({
  selector: 'sans-home',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, MatButtonModule, RouterLink],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  tagline = $localize`:Tagline|Site tagline:The ultimate place to play online Preferans`
  createGameButtonLabel = $localize`:Button label|Create game button label:Create game`
  learnPreferansButtonLabel = $localize`:Button label|Learn preferans button label:Learn preferans`

  constructor(
    @Inject(APP_NAME) public appName: string,
    public logoAssets: LogoAssets,
  ) {}
}
