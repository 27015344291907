import { ViewGamePlayer } from './view-game-player'
import { ViewGameResponse } from '../responses/view-game.response'

export class ViewGame {
  readonly gameShortId = this.viewGameResponse.gameShortId
  readonly viewGameStatus = this.viewGameResponse.viewGameStatus
  readonly gameSettings = this.viewGameResponse.gameSettings
  readonly players = this.viewGameResponse.players.map(
    (player) => new ViewGamePlayer(player),
  )
  readonly isUserCreator = this.viewGameResponse.isUserCreator
  readonly canUserCancel = this.viewGameResponse.canUserCancel
  readonly createdAt = this.viewGameResponse.createdAt
  readonly creator = this.players.find((player) => player.isCreator)!
  readonly leftPlayer = this.players.find((player) => player.isLeftPlayer)!
  readonly rightPlayer = this.players.find((player) => player.isRightPlayer)!

  constructor(private viewGameResponse: ViewGameResponse) {}
}
