import { CanActivateFn } from '@angular/router'
import { inject } from '@angular/core'
import { UserService } from '../services/user.service'
import { firstValueFrom } from 'rxjs'

export const isUserSignedInGuard: CanActivateFn =
  async (): Promise<boolean> => {
    const userService = inject(UserService)

    const isUserFetchedAtLeastOnce = await firstValueFrom(
      userService.isUserFetchedAtLeastOnce$,
    )

    if (!isUserFetchedAtLeastOnce) {
      await userService.nextUser()
    }

    const user = await firstValueFrom(userService.user$)
    return user.isSignedIn
  }
