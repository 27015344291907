<sans-identity-template [title]="title">
  <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="form">
    <mat-form-field class="form-field">
      <mat-label>{{ emailLabel }}</mat-label>
      <input readonly matInput value="{{ email }}" />
    </mat-form-field>

    <sans-password-input
      [passwordLabel]="newPasswordLabel"
      [formGroup]="resetPasswordForm"
      passwordFormControlName="newPassword"
      class="form-field"
    ></sans-password-input>

    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="submit-button"
    >
      {{ submitLabel }}
    </button>
  </form>

  <span>
    <a
      mat-button
      routerLink="/request-password-reset"
      queryParamsHandling="preserve"
      >{{ requestPasswordResetLabel }}</a
    >
  </span>
</sans-identity-template>
