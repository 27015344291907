import { Component, Inject } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { NgForOf, NgIf } from '@angular/common'
import { RequestPasswordResetResponse } from '../../responses/request-password-reset-response/request-password-reset.response'

@Component({
  selector: 'sans-request-password-reset-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    NgForOf,
    NgIf,
  ],
  templateUrl: './request-password-reset-dialog.component.html',
  styleUrl: './request-password-reset-dialog.component.scss',
})
export class RequestPasswordResetDialogComponent {
  successfulTitle = $localize`:Title|Request password reset dialog title:Request succeeded. Please check your inbox to continue with resetting your password`
  notSuccessfulTitle = $localize`:Title|Not successful request password reset dialog title:Oops something went wrong`
  closeLabel = $localize`:Label|Close label:Close`

  isSuccessful = this.requestPasswordResetResponse.isSuccessful
  errorDescriptions = this.requestPasswordResetResponse.getErrorDescriptions()

  constructor(
    private dialogRef: MatDialogRef<RequestPasswordResetDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private requestPasswordResetResponse: RequestPasswordResetResponse,
  ) {}

  onClose() {
    this.dialogRef.close()
  }
}
