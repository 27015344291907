import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { SideMenuService } from '../../../core/services/side-menu/side-menu.service'
import { MatButtonModule } from '@angular/material/button'
import { UserService } from '../../services/user.service'
import { Router, RouterLink } from '@angular/router'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatDialog } from '@angular/material/dialog'
import { SetUsernameDialogComponent } from '../set-username-dialog/set-username-dialog.component'
import { InputFieldComponent } from '../../../core/components/input-field/input-field.component'
import { SetNameDialogComponent } from '../set-name-dialog/set-name-dialog.component'
import { USERNAME_REGEX } from '../../providers/username-regex.provider'
import { SetUsernameResponse } from '../../responses/set-username-response/set-username.response'

@Component({
  selector: 'sans-account-menu',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    RouterLink,
    MatFormFieldModule,
    MatInputModule,
    InputFieldComponent,
  ],
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent {
  emailLabel = $localize`:Email label|Account menu email label:Email`
  usernameLabel = $localize`:Username label|Account menu username label:Username`
  firstNameLabel = $localize`:First name label|Account menu first name label:First name`
  lastNameLabel = $localize`:Last name label|Account menu last name label:Last name`

  closeAriaLabel = $localize`:Aria label|Close account menu aria label:Close account menu`
  signOutLabel = $localize`:Button label|Button label account menu:Sign out`

  isOpen$ = this.sideMenuService.isOpen$
  user$ = this.userService.user$

  constructor(
    private sideMenuService: SideMenuService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    @Inject(USERNAME_REGEX) private usernameRegEx: RegExp,
  ) {}

  async onSaveUsername(username: string): Promise<void> {
    if (username == this.userService.user.username) {
      return
    }

    if (!this.usernameRegEx.test(username)) {
      this.dialog.open(SetUsernameDialogComponent, {
        data: SetUsernameResponse.withError('InvalidUsername'),
      })
      return
    }

    const response = await this.userService.setUsername(username)

    if (!response.isSuccessful) {
      this.dialog.open(SetUsernameDialogComponent, { data: response })
    }
  }

  async onSaveFirstName(firstName: string): Promise<void> {
    if (firstName == this.userService.user.firstName) {
      return
    }

    const response = await this.userService.setFirstName(firstName)

    if (!response.isSuccessful) {
      this.dialog.open(SetNameDialogComponent, { data: response })
    }
  }

  async onSaveLastName(lastName: string): Promise<void> {
    if (lastName == this.userService.user.lastName) {
      return
    }

    const response = await this.userService.setLastName(lastName)

    if (!response.isSuccessful) {
      this.dialog.open(SetNameDialogComponent, { data: response })
    }
  }

  onCloseAccountMenu(): void {
    this.sideMenuService.setIsOpen(false)
  }

  async onSignOut(): Promise<void> {
    await this.userService.signOut()
    this.sideMenuService.setIsOpen(false)
    await this.router.navigate(['/'])
  }
}
