import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'

@Component({
  selector: 'sans-sign-in-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  templateUrl: './sign-in-dialog.component.html',
  styleUrls: ['./sign-in-dialog.component.scss'],
})
export class SignInDialogComponent {
  title = $localize`:Dialog title|Unable to sign in dialog title:Oops something went wrong`
  message = $localize`:Dialog message|Sign in dialog message:Please check your username and password are correct`
  closeLabel = $localize`:Label|Close label:Close`

  constructor(public dialogRef: MatDialogRef<SignInDialogComponent>) {}

  onClose() {
    this.dialogRef.close()
  }
}
