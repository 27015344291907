import { CommandApiResponse } from '../../core/services/models/command-api.response'
import { ConfirmEmailErrorCode } from '../error-codes/confirm-email.error-code'
import { CommandApiResponseData } from '../../core/services/models/command-api.response-data'

export class ConfirmEmailResponse extends CommandApiResponse<ConfirmEmailErrorCode> {
  private constructor() {
    super()
  }

  static fromApiResponse(
    commandApiResponseData: CommandApiResponseData<ConfirmEmailErrorCode>,
  ): ConfirmEmailResponse {
    const response = new ConfirmEmailResponse()
    response._errorCode = commandApiResponseData.errorCode
    response._errorCodes = commandApiResponseData.errorCodes
    response._isSuccessful = commandApiResponseData.isSuccessful

    return response
  }

  static asNotSuccessful(
    errorCode: ConfirmEmailErrorCode,
  ): ConfirmEmailResponse {
    const response = new ConfirmEmailResponse()
    response._isSuccessful = false
    response._errorCode = errorCode
    response._errorCodes = [errorCode]
    return response
  }
}
