import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class StorageService {
  storage = window.localStorage

  getItemStr(key: string): string | null {
    return this.storage.getItem(key)
  }

  setItemStr(key: string, value: string): void {
    this.storage.setItem(key, value)
  }
}
