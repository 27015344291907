import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { CommandApiResponse } from '../../../core/services/models/command-api.response'
import { ConfirmEmailErrorCode } from '../../error-codes/confirm-email.error-code'

@Component({
  selector: 'sans-confirm-email-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  templateUrl: './confirm-email-dialog.component.html',
  styleUrls: ['./confirm-email-dialog.component.scss'],
})
export class ConfirmEmailDialogComponent {
  successfulTitle = $localize`:Title|Successful confirm email dialog title:Congratulations you have successfully confirmed your email`
  notSuccessfulTitle = $localize`:Title|Not successful confirm email dialog title:Oops something went wrong`
  closeLabel = $localize`:Label|Close label:Close`

  isSuccessful = this.response.isSuccessful
  errors = this.response.errorCode

  constructor(
    public dialogRef: MatDialogRef<ConfirmEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private response: CommandApiResponse<ConfirmEmailErrorCode>,
  ) {}

  onClose() {
    this.dialogRef.close()
  }
}
