import { Injectable } from '@angular/core'
import { ViewGamesViewModel } from '../view-games/view-games.view-model'
import { GamePlayService } from '../../../../game-play/services/game-play.service'
import { map } from 'rxjs'
import { UserService } from '../../../../identity/services/user.service'
import { ViewGamePlayer } from '../../models/view-game-player'
import { ViewGamePlayerNull } from '../../models/view-game-player.null'
import { ViewGame } from '../../models/view-game'
import { ViewGameNull } from '../../models/view-game.null'
import { GameSetupPlayerService } from '../../services/game-setup-player/game-setup-player.service'

@Injectable()
export class ViewGamePlayerViewModel {
  private _player: ViewGamePlayer = new ViewGamePlayerNull('Left', false)
  private _viewGame: ViewGame = new ViewGameNull()
  private _showSitAtGame = false
  private _showLeaveGame = false
  private _showDisplayName = false

  readonly invitedLabel = $localize`:Invited label|Game setup player cell component:Player invited`
  readonly sitButtonLabel = $localize`:Sit button label|Game setup player cell component:Sit`
  readonly leaveGameButtonLabel = $localize`:Leave game button label|Game setup player cell component:Leave`

  get showSitAtGame(): boolean {
    return this._showSitAtGame
  }

  get showLeaveGame(): boolean {
    return this._showLeaveGame
  }

  get showDisplayName(): boolean {
    return this._showDisplayName
  }

  showInvited$ = this.userService.user$.pipe(
    map((user) => {
      if (!user.isSignedIn) {
        return false
      }

      if (this._player.hasSat) {
        return false
      }

      return this._viewGame.isUserCreator && this._player.isInvited
    }),
  )

  constructor(
    private viewGamesViewModel: ViewGamesViewModel,
    private gamePlayService: GamePlayService,
    private userService: UserService,
    private gameSetupPlayerService: GameSetupPlayerService,
  ) {}

  onViewInit(player: ViewGamePlayer, viewGame: ViewGame): void {
    this._player = player
    this._viewGame = viewGame

    this._showSitAtGame =
      this._player.canUserSitAtGame || this._player.canUserAcceptInvite

    this._showLeaveGame = this._player.canUserLeaveGame

    this._showDisplayName =
      !this.showSitAtGame &&
      !this._player.canUserLeaveGame &&
      this._player.hasSat
  }

  async onSitSelected(): Promise<void> {
    if (this._player.canUserSitAtGame) {
      await this.gameSetupPlayerService.sitAtGame(
        this._viewGame.gameShortId,
        this._player.sitPosition,
      )
    }

    if (this._player.canUserAcceptInvite) {
      await this.gameSetupPlayerService.acceptInvite(
        this._viewGame.gameShortId,
        this._player.sitPosition,
      )
    }

    const hasGameInProgress = await this.gamePlayService.hasGameInProgress()

    if (!hasGameInProgress) {
      await this.viewGamesViewModel.getGames()
    }
  }

  async onLeaveGameSelected(): Promise<void> {
    await this.gameSetupPlayerService.leaveGame(
      this._viewGame.gameShortId,
      this._player.sitPosition,
    )

    await this.viewGamesViewModel.getGames()
  }
}
