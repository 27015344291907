import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  private readonly _isOpen$ = new BehaviorSubject<boolean>(false)
  readonly isOpen$ = this._isOpen$.asObservable()

  setIsOpen(isOpen: boolean) {
    this._isOpen$.next(isOpen)
  }
}
