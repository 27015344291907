import { Injectable } from '@angular/core'
import { ViewGame } from '../../models/view-game'
import { firstValueFrom } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { GameSetupConfigs } from '../../../configs/game-setup.configs'
import { CommandApiResponse } from '../../../../core/services/models/command-api.response'
import { CancelGameSetupErrorCode } from '../../error-codes/cancel-game-setup.error-code'
import { ViewGameResponse } from '../../responses/view-game.response'

@Injectable()
export class ViewGamesService {
  constructor(
    private httpClient: HttpClient,
    private gameSetupConfigs: GameSetupConfigs,
  ) {}

  async getGames(): Promise<ViewGame[]> {
    const gamesResponseList = await firstValueFrom(
      this.httpClient.get<ViewGameResponse[]>(
        `${this.gameSetupConfigs.baseUrl}/games`,
        {
          withCredentials: true,
        },
      ),
    )

    return gamesResponseList.map((response) => new ViewGame(response))
  }

  async getGamesAsGuest(): Promise<ViewGame[]> {
    const gamesResponseList = await firstValueFrom(
      this.httpClient.get<ViewGameResponse[]>(
        `${this.gameSetupConfigs.baseUrl}/games-as-guest`,
        {
          withCredentials: true,
        },
      ),
    )

    return gamesResponseList.map((response) => new ViewGame(response))
  }

  async cancelGameSetup(
    gameShortId: string,
  ): Promise<CommandApiResponse<CancelGameSetupErrorCode>> {
    return await firstValueFrom(
      this.httpClient.post<CommandApiResponse<CancelGameSetupErrorCode>>(
        `${this.gameSetupConfigs.baseUrl}/cancel-game`,
        {
          gameShortId,
        },
        {
          withCredentials: true,
        },
      ),
    )
  }
}
