import { Component, Inject } from '@angular/core'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
} from '@angular/common'
import { ThemeService } from '../../services/theme/theme.service'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterLink, RouterLinkActive } from '@angular/router'
import { MatMenuModule } from '@angular/material/menu'
import {
  LanguageLocale,
  LocaleId,
} from '../../services/locales/language-locale'
import { UserService } from '../../../identity/services/user.service'
import { APP_NAME } from '../../providers/app-name/app-name.provider'
import { SideMenuService } from '../../services/side-menu/side-menu.service'
import { LogoAssets } from '../../providers/logo-assets/logo-assets.provider'
import { map } from 'rxjs'
import { LocaleService } from '../../services/locales/locale.service'
import { WindowService } from '../../services/window/window.service'

@Component({
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    NgOptimizedImage,
    MatTooltipModule,
    RouterLink,
    MatMenuModule,
    NgForOf,
    RouterLinkActive,
    NgIf,
    AsyncPipe,
    NgClass,
  ],
  selector: 'sans-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  viewGamesLabel = $localize`:Button label|Button label toolbar:View games`
  createGameLabel = $localize`:Button label|Button label toolbar:Create game`
  learnPreferansLabel = $localize`:Button label|Button label toolbar:Learn preferans`

  signInLabel = $localize`:Button label|Button label toolbar:Sign in`
  signUpLabel = $localize`:Button label|Button label toolbar:Sign up`

  languageLocales = this.localeService.languageLocales
  ariaCurrentWhenActive = 'page' as const

  constructor(
    @Inject(APP_NAME) public appName: string,
    public logoAssets: LogoAssets,
    private themeService: ThemeService,
    private localeService: LocaleService,
    private userService: UserService,
    private sideMenuService: SideMenuService,
    private windowService: WindowService,
  ) {}

  user$ = this.userService.user$

  themeTooltipLabel$ = this.themeService.theme$.pipe(
    map((theme) =>
      theme === 'Light'
        ? $localize`:Tooltip label|Light tooltip label:Switch to dark mode`
        : $localize`:Tooltip label|Dark tooltip label:Switch to light mode`,
    ),
  )

  themeIconName$ = this.themeService.theme$.pipe(
    map((theme) => (theme === 'Light' ? 'dark_mode' : 'light_mode')),
  )

  isUserFetchedAtLeastOnce$ = this.userService.isUserFetchedAtLeastOnce$
  languageTooltipLabel = $localize`:Tooltip label|Language tooltip label:Choose language`

  onToggleTheme(): void {
    this.themeService.toggleTheme()
  }

  trackByLanguageLocale(_: number, languageLocale: LanguageLocale): LocaleId {
    return languageLocale.localeId
  }

  onSetLanguageLocale(languageLocale: LanguageLocale): void {
    this.localeService.setLanguageLocale(languageLocale)

    if (this.localeService.isUserLocaleDifferentFromAppLocale()) {
      this.windowService.reload(languageLocale)
    }
  }

  onShowSideMenu(): void {
    this.sideMenuService.setIsOpen(true)
  }
}
