import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, firstValueFrom, map } from 'rxjs'
import { IdentityConfigs } from '../providers/identity-configs.provider'
import { User } from '../models/user'
import { ConfirmEmailResponse } from '../responses/confirm-email.response'
import { WindowService } from '../../core/services/window/window.service'
import { UserCommandApiResponse } from '../responses/user-command-api.response'
import { SignUpErrorCode } from '../error-codes/sign-up.error-code'
import { ConfirmEmailErrorCode } from '../error-codes/confirm-email.error-code'
import { SignUpResponse } from '../responses/sign-up-response/sign-up.response'
import { SignInErrorCode } from '../error-codes/sign-in.error-code'
import { UserResponse } from '../responses/user.response'
import { RequestPasswordResetErrorCode } from '../error-codes/request-password-reset.error-code'
import { RequestPasswordResetResponse } from '../responses/request-password-reset-response/request-password-reset.response'
import { ResetPasswordResponse } from '../responses/reset-password-response/reset-password.response'
import { ResetPasswordErrorCode } from '../error-codes/reset-password.error-code'
import { SetUsernameErrorCode } from '../error-codes/set-username.error-code'
import { SetUsernameResponse } from '../responses/set-username-response/set-username.response'
import { SetNameResponse } from '../responses/set-name-response/set-name.response'
import { SetNameErrorCode } from '../error-codes/set-name.error-code'
import { CommandApiResponseData } from '../../core/services/models/command-api.response-data'
import { SignInResponse } from '../responses/sign-in.response'
import { LocaleService } from '../../core/services/locales/locale.service'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user$ = new BehaviorSubject<User>(User.asNotSignedIn())
  private _userFetchedAtLeastOnce$ = new BehaviorSubject<boolean>(false)

  readonly user$ = this._user$.asObservable()
  readonly userFetchedAtLeastOnce$ =
    this._userFetchedAtLeastOnce$.asObservable()

  get user(): User {
    return this._user$.value
  }

  private readonly _isUserFetchedAtLeastOnce$ = new BehaviorSubject<boolean>(
    false,
  )
  readonly isUserFetchedAtLeastOnce$ =
    this._isUserFetchedAtLeastOnce$.asObservable()

  constructor(
    private httpClient: HttpClient,
    private identityConfigs: IdentityConfigs,
    private windowService: WindowService,
    private localeService: LocaleService,
  ) {}

  async nextUser(): Promise<void> {
    const user = await firstValueFrom(
      this.httpClient
        .get<UserResponse>(`${this.identityConfigs.apiBaseUrl}/user`, {
          withCredentials: true,
        })
        .pipe(map((userResponse) => User.asSignedIn(userResponse))),
    )
      .catch(() => {
        return User.asNotSignedIn()
      })
      .finally(() => this._userFetchedAtLeastOnce$.next(true))

    this._user$.next(user)
    this._isUserFetchedAtLeastOnce$.next(true)
  }

  async signOut(): Promise<void> {
    await firstValueFrom(
      this.httpClient.post(
        `${this.identityConfigs.apiBaseUrl}/sign-out`,
        {},
        { withCredentials: true },
      ),
    )

    this._user$.next(User.asNotSignedIn())
  }

  async signIn(
    emailOrUsername: string,
    password: string,
  ): Promise<SignInResponse> {
    const body = { emailOrUsername, password }

    const response = await firstValueFrom(
      this.httpClient.post<UserCommandApiResponse<SignInErrorCode>>(
        `${this.identityConfigs.apiBaseUrl}/sign-in`,
        body,
        { withCredentials: true },
      ),
    )

    if (response.isSuccessful) {
      this._user$.next(User.asSignedIn(response.user))
    }

    return SignInResponse.fromApiResponse(response)
  }

  signUp(email: string, password: string): Promise<SignUpResponse> {
    const localeId = this.localeService.languageLocale.localeId
    const body = { email, password, localeId }

    return firstValueFrom(
      this.httpClient
        .post<CommandApiResponseData<SignUpErrorCode>>(
          `${this.identityConfigs.apiBaseUrl}/sign-up`,
          body,
        )
        .pipe(
          map((response) =>
            SignUpResponse.fromApiResponse(response, this.identityConfigs),
          ),
        ),
    )
  }

  async confirmEmail(): Promise<ConfirmEmailResponse> {
    const email = this.windowService.findQueryParameter('email')
    const token = this.windowService.findQueryParameter('token')

    if (!email || !token) {
      return ConfirmEmailResponse.asNotSuccessful('InvalidToken')
    }

    const body = { email, token }

    return await firstValueFrom(
      this.httpClient
        .post<CommandApiResponseData<ConfirmEmailErrorCode>>(
          `${this.identityConfigs.apiBaseUrl}/confirm-email`,
          body,
        )
        .pipe(
          map((response) => ConfirmEmailResponse.fromApiResponse(response)),
        ),
    )
  }

  async requestPasswordReset(
    email: string,
  ): Promise<RequestPasswordResetResponse> {
    const localeId = this.localeService.languageLocale.localeId
    const body = { email, localeId }

    return await firstValueFrom(
      this.httpClient
        .post<CommandApiResponseData<RequestPasswordResetErrorCode>>(
          `${this.identityConfigs.apiBaseUrl}/request-password-reset`,
          body,
          { withCredentials: true },
        )
        .pipe(
          map((response) =>
            RequestPasswordResetResponse.fromApiResponse(response),
          ),
        ),
    )
  }

  async resetPassword(newPassword: string): Promise<ResetPasswordResponse> {
    const email = this.windowService.findQueryParameter('email')
    const token = this.windowService.findQueryParameter('token')
    const body = { email, resetCode: token, newPassword }

    return await firstValueFrom(
      this.httpClient
        .post<CommandApiResponseData<ResetPasswordErrorCode>>(
          `${this.identityConfigs.apiBaseUrl}/reset-password`,
          body,
          { withCredentials: true },
        )
        .pipe(
          map((response) => ResetPasswordResponse.fromApiResponse(response)),
        ),
    )
  }

  async setUsername(username: string): Promise<SetUsernameResponse> {
    const body = { username }

    const response = await firstValueFrom(
      this.httpClient.post<UserCommandApiResponse<SetUsernameErrorCode>>(
        `${this.identityConfigs.apiBaseUrl}/set-username`,
        body,
        { withCredentials: true },
      ),
    )

    if (response.isSuccessful) {
      this._user$.next(User.asSignedIn(response.user))
    }

    return SetUsernameResponse.fromApiResponse(response)
  }

  async setFirstName(firstName: string): Promise<SetNameResponse> {
    const body = { name: firstName }

    const response = await firstValueFrom(
      this.httpClient.post<UserCommandApiResponse<SetNameErrorCode>>(
        `${this.identityConfigs.apiBaseUrl}/set-first-name`,
        body,
        { withCredentials: true },
      ),
    )

    if (response.isSuccessful) {
      this._user$.next(User.asSignedIn(response.user))
    }

    return SetNameResponse.fromApiResponse(response)
  }

  async setLastName(lastName: string): Promise<SetNameResponse> {
    const body = { name: lastName }

    const response = await firstValueFrom(
      this.httpClient.post<UserCommandApiResponse<SetNameErrorCode>>(
        `${this.identityConfigs.apiBaseUrl}/set-last-name`,
        body,
        { withCredentials: true },
      ),
    )

    if (response.isSuccessful) {
      this._user$.next(User.asSignedIn(response.user))
    }

    return SetNameResponse.fromApiResponse(response)
  }
}
