export type LanguageLocalized = 'English' | 'Srpski'
export type LocaleId = 'en' | 'sr'

export class LanguageLocale {
  get languageId(): string {
    return this.localeId.split('-')[0]
  }

  get localeExtension(): string {
    const split = this.localeId.split('-')

    if (split.length === 1) {
      return ''
    }

    return split[1]
  }

  constructor(
    public readonly language: LanguageLocalized,
    public readonly localeId: LocaleId,
  ) {}
}
