import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { RouterLink } from '@angular/router'
import { ViewGamesComponent } from '../view-games/view-games.component'
import { NoGamesComponent } from '../no-games/no-games.component'
import { USERNAME_NOT_SET_LABEL } from '../../../../core/providers/username-not-set-label/username-not-set-label.provider'
import { ViewGamesViewModel } from '../../view-models/view-games/view-games.view-model'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { ViewGamesFilter } from '../../view-models/view-games/view-games.filter'

@Component({
  selector: 'sans-view-games-container',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    ViewGamesComponent,
    NoGamesComponent,
    MatButtonToggleModule,
  ],
  templateUrl: './view-games-container.component.html',
  styleUrls: ['./view-games-container.component.scss'],
  providers: [ViewGamesViewModel],
})
export class ViewGamesContainerComponent implements OnInit, OnDestroy {
  refreshGamesButtonLabel = $localize`:Refresh games button label|View games component:Refresh games`
  waitingGamesFilterSelectionLabel = $localize`:Waiting games filter selection label|View games component:Waiting games`
  allGamesFilterSelectionLabel = $localize`:All games filter selection label|View games component:All games`
  viewGamesFilterAreaLabel = $localize`:View games filter area label|View games component:View games filter area label`

  waitingGamesFilterSelection: ViewGamesFilter = 'WaitingGames'
  allGamesFilterSelection: ViewGamesFilter = 'AllGames'

  constructor(
    public viewModel: ViewGamesViewModel,
    @Inject(USERNAME_NOT_SET_LABEL) public usernameNotSetLabel: string,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.viewModel.onViewInit()
  }

  ngOnDestroy(): void {
    this.viewModel.onViewDestroy()
  }
}
