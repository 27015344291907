import { CanActivateFn } from '@angular/router'
import { isUserSignedInGuard } from './is-user-signed-in.guard'

export const isUserNotSignedInGuard: CanActivateFn = async (
  route,
  state,
): Promise<boolean> => {
  const isUserSignedIn = await isUserSignedInGuard(route, state)
  return !isUserSignedIn
}
