<div class="container">
  <div class="close-button">
    <button
      mat-icon-button
      [attr.aria-label]="closeAriaLabel"
      (click)="onCloseAccountMenu()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="isOpen$ | async">
    <ng-container *ngIf="user$ | async as user">
      <mat-form-field class="form-field">
        <mat-label>{{ emailLabel }}</mat-label>
        <input readonly matInput value="{{ user.email }}" />
      </mat-form-field>

      <br />
      <br />

      <sans-input-field
        class="form-field"
        [inputLabel]="usernameLabel"
        [isReadonly]="true"
        [inputValue]="user.username"
        [isMandatory]="!user.isUsernameSet"
        (onSaveSelected)="onSaveUsername($event)"
      >
      </sans-input-field>

      <sans-input-field
        class="form-field"
        [inputLabel]="firstNameLabel"
        [isReadonly]="true"
        [inputValue]="user.firstName"
        (onSaveSelected)="onSaveFirstName($event)"
      >
      </sans-input-field>

      <sans-input-field
        class="form-field"
        [inputLabel]="lastNameLabel"
        [isReadonly]="true"
        [inputValue]="user.lastName"
        (onSaveSelected)="onSaveLastName($event)"
      >
      </sans-input-field>
    </ng-container>

    <div class="sign-out-button">
      <button mat-button (click)="onSignOut()">
        {{ signOutLabel }}
      </button>
    </div>
  </ng-container>
</div>
