<ng-container [formGroup]="createGameSetupForm">
  <mat-form-field>
    <mat-label>{{ playerLabel }}</mat-label>
    <input
      type="text"
      matInput
      placeholder="{{ playerPlaceholder }}"
      [formControlName]="playerControlName"
      [attr.aria-label]="playerLabel"
      [matAutocomplete]="playerAutocomplete"
    />
    <mat-autocomplete
      autoActiveFirstOption="true"
      #playerAutocomplete="matAutocomplete"
    >
      <mat-option
        *ngFor="let filteredPlayer of filteredPlayerOptions"
        [value]="filteredPlayer.username"
      >
        {{ filteredPlayer.username }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint>{{ searchPlayerHint }}</mat-hint>
    <mat-error>{{ playerError }}</mat-error>
  </mat-form-field>
</ng-container>
