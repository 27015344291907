import { InjectionToken, Provider } from '@angular/core'
import { LanguageLocale } from './language-locale'

export const LANGUAGE_LOCALES = new InjectionToken<LanguageLocale[]>(
  'Language locales',
)

export const provideLanguageLocales: Provider[] = [
  {
    provide: LANGUAGE_LOCALES,
    multi: true,
    useValue: new LanguageLocale('English', 'en'),
  },
  {
    provide: LANGUAGE_LOCALES,
    multi: true,
    useValue: new LanguageLocale('Srpski', 'sr'),
  },
]
