import { FilterPlayersErrorCode } from './filter-players.error-code'
import { ValidationErrors } from '@angular/forms'

export class FilterPlayersErrorProvider {
  getValidationError(errorCode: FilterPlayersErrorCode): ValidationErrors {
    if (errorCode === 'PlayerUsernameNotFound') {
      return {
        playerUsernameNotFound: true,
      }
    }

    throw new Error(
      `Unable to ${this.getValidationError.name} for errorCode ${errorCode}`,
    )
  }

  mapToErrorCodeStr(errorCode: FilterPlayersErrorCode): string {
    if (errorCode === 'PlayerUsernameNotFound') {
      return 'playerUsernameNotFound'
    }

    throw new Error(
      `Unable to ${this.mapToErrorCodeStr.name} for errorCode ${errorCode}`,
    )
  }
}
