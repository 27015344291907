<ng-container *ngIf="viewModel.gamesFetched$ | async">
  <h3 *ngIf="viewModel.showUsernameNotSetLabel$ | async" class="username-not-set">
    {{ usernameNotSetLabel }}
  </h3>

  <div class="game-selections">
    <mat-button-toggle-group
      name="viewGamesFilter"
      [attr.aria-label]="viewGamesFilterAreaLabel"
    >
      <mat-button-toggle
        [attr.aria-label]="waitingGamesFilterSelectionLabel"
        [value]="waitingGamesFilterSelection"
        checked="true"
        (change)="viewModel.onViewGamesFilterSelected($event.value)"
        >{{ waitingGamesFilterSelectionLabel }}</mat-button-toggle
      >
      <mat-button-toggle
        [attr.aria-label]="allGamesFilterSelectionLabel"
        (change)="viewModel.onViewGamesFilterSelected($event.value)"
        [value]="allGamesFilterSelection"
        >{{ allGamesFilterSelectionLabel }}</mat-button-toggle
      >
    </mat-button-toggle-group>

    <button
      mat-raised-button
      color="primary"
      (click)="viewModel.onRefreshGamesSelected()"
    >
      {{ refreshGamesButtonLabel }}
    </button>
  </div>

  <ng-container *ngIf="viewModel.hasGames$ | async; else noGames">
    <sans-view-games></sans-view-games>
  </ng-container>

  <ng-template #noGames>
    <sans-no-games></sans-no-games>
  </ng-template>
</ng-container>
