<h1 mat-dialog-title>{{ title }}</h1>

<div mat-dialog-content>
  <ul>
    <li *ngFor="let errorDescription of errorDescriptions">
      {{ errorDescription }}
    </li>
  </ul>
</div>

<div mat-dialog-actions class="dialog-action">
  <button mat-button (click)="onClose()">{{ closeLabel }}</button>
</div>
