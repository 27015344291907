import { Route } from '@angular/router'
import { HomeComponent } from '../home/home.component'
import { CreateGameSetupComponent } from '../game-setup/create-game-setup/components/create-game-setup/create-game-setup.component'
import { LearnPreferansComponent } from '../learn-preferans/learn-preferans.component'
import { PageNotFoundComponent } from '../core/components/page-not-found/page-not-found.component'
import { SignInComponent } from '../identity/components/sign-in/sign-in.component'
import { SignUpComponent } from '../identity/components/sign-up/sign-up.component'
import { ConfirmEmailComponent } from '../identity/components/confirm-email/confirm-email.component'
import { CreateGameSetupUnauthorizedComponent } from '../game-setup/create-game-setup/components/create-game-setup-unauthorized/create-game-setup-unauthorized.component'
import { AlreadySignedInComponent } from '../identity/components/already-signed-in/already-signed-in.component'
import { isUserNotSignedInGuard } from '../identity/guards/is-user-not-signed-in.guard'
import { createGameSetupErrorGuard } from '../game-setup/create-game-setup/guards/create-game-setup-error.guard'
import { CreateGameSetupErrorComponent } from '../game-setup/create-game-setup/components/create-game-setup-error/create-game-setup-error.component'
import { isUserSignedInGuard } from '../identity/guards/is-user-signed-in.guard'
import { CreateGameSetupErrorCodeSetter } from '../game-setup/create-game-setup/error-codes/error-status-provider/create-game-setup-error-code.setter'
import { CreateGameSetupErrorCodeProvider } from '../game-setup/create-game-setup/error-codes/error-status-provider/create-game-setup-error-code.provider'
import { CreateGameSetupService } from '../game-setup/create-game-setup/services/create-game-setup.service'
import { ViewGamesService } from '../game-setup/view-games/services/view-games/view-games.service'
import { GameSetupPlayerService } from '../game-setup/view-games/services/game-setup-player/game-setup-player.service'
import { ViewGamesContainerComponent } from '../game-setup/view-games/components/view-games-container/view-games-container.component'
import { RequestPasswordResetComponent } from '../identity/components/request-password-reset/request-password-reset.component'
import { ResetPasswordComponent } from '../identity/components/reset-password/reset-password.component'
import { CreateGameSetupContent } from '../game-setup/create-game-setup/contents/create-game-setup.content'

export const appRoutes: Route[] = [
  {
    path: 'view-games',
    component: ViewGamesContainerComponent,
    providers: [ViewGamesService, GameSetupPlayerService],
  },
  {
    path: 'create-game',
    component: CreateGameSetupUnauthorizedComponent,
    canMatch: [isUserNotSignedInGuard],
  },
  {
    path: 'create-game',
    component: CreateGameSetupErrorComponent,
    canMatch: [createGameSetupErrorGuard],
    providers: [
      CreateGameSetupService,
      CreateGameSetupErrorCodeSetter,
      CreateGameSetupContent,
      {
        provide: CreateGameSetupErrorCodeProvider,
        useExisting: CreateGameSetupErrorCodeSetter,
      },
    ],
  },
  {
    path: 'create-game',
    component: CreateGameSetupComponent,
    providers: [CreateGameSetupService],
  },
  {
    path: 'learn-preferans',
    component: LearnPreferansComponent,
  },
  {
    path: 'sign-in',
    component: AlreadySignedInComponent,
    canMatch: [isUserSignedInGuard],
  },
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: 'sign-up',
    component: AlreadySignedInComponent,
    canMatch: [isUserSignedInGuard],
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
  },
  {
    path: 'confirm-email',
    component: ConfirmEmailComponent,
  },
  {
    path: 'request-password-reset',
    component: RequestPasswordResetComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
]
