import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { AsyncPipe, NgClass, NgIf } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'

@Component({
  selector: 'sans-input-field',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgIf,
    AsyncPipe,
    MatTooltipModule,
    NgClass,
  ],
  templateUrl: './input-field.component.html',
  styleUrl: './input-field.component.scss',
})
export class InputFieldComponent {
  @Input() inputLabel!: string
  @Input() isReadonly!: boolean
  @Input() inputValue: string = ''
  @Input() isMandatory: boolean = false
  @Output() onSaveSelected = new EventEmitter<string>()

  editIconAriaLabel = $localize`:Icon area label|Edit icon:Edit`
  saveIconAriaLabel = $localize`:Icon area label|Save icon:Save`
  cancelEditIconAriaLabel = $localize`:Icon area label|Cancel edit icon:Cancel`

  editTooltipLabel = $localize`:Icon tooltip label|Edit tooltip icon:Edit`
  saveTooltipLabel = $localize`:Icon tooltip label|Save tooltip icon:Save`
  cancelEditTooltipLabel = $localize`:Icon tooltip label|Cancel edit tooltip icon:Cancel`

  tooltipShowDelay = 1000

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  onEditInputFieldSelected(): void {
    this.isReadonly = !this.isReadonly
  }

  async onSaveInputFieldSelected(inputValue: string): Promise<void> {
    this.isReadonly = !this.isReadonly
    this.onSaveSelected.emit(inputValue.trim())
  }

  async onCancelEditSelected(): Promise<void> {
    this.isReadonly = !this.isReadonly
    const inputValueTemp = this.inputValue
    this.inputValue = ''
    this.changeDetectorRef.detectChanges()
    this.inputValue = inputValueTemp
  }
}
