<mat-toolbar color="primary">
  <a
    routerLink="/"
    routerLinkActive="nav-active home"
    [ariaCurrentWhenActive]="ariaCurrentWhenActive"
  >
    <img
      class="logo-img"
      [ngSrc]="logoAssets.imgSrc"
      [width]="38"
      [height]="38"
      [alt]="logoAssets.altImg"
      priority="high"
    />
    <span class="app-name">{{ appName }}</span>
  </a>

  <a
    mat-button
    class="nav-link"
    routerLink="/view-games"
    routerLinkActive="nav-active"
    [ariaCurrentWhenActive]="ariaCurrentWhenActive"
  >
    {{ viewGamesLabel }}
  </a>
  <a
    mat-button
    class="nav-link"
    routerLink="/create-game"
    routerLinkActive="nav-active"
    [ariaCurrentWhenActive]="ariaCurrentWhenActive"
  >
    {{ createGameLabel }}
  </a>
  <a
    mat-button
    class="nav-link"
    routerLink="/learn-preferans"
    routerLinkActive="nav-active"
    [ariaCurrentWhenActive]="ariaCurrentWhenActive"
  >
    {{ learnPreferansLabel }}
  </a>

  <span class="spacer"></span>

  <ng-container *ngIf="isUserFetchedAtLeastOnce$ | async">
    <ng-container *ngIf="user$ | async as user">
      <button
        mat-icon-button
        [attr.aria-label]="themeTooltipLabel$ | async"
        [matTooltip]="(themeTooltipLabel$ | async)!"
        (click)="onToggleTheme()"
      >
        <mat-icon>{{ themeIconName$ | async }}</mat-icon>
      </button>

      <button
        mat-icon-button
        [attr.aria-label]="languageTooltipLabel"
        [matTooltip]="languageTooltipLabel"
        [matMenuTriggerFor]="localeMenu"
      >
        <mat-icon>language</mat-icon>
      </button>

      <div
        [ngClass]="user.isUsernameSet ? '' : 'account-notification'"
        *ngIf="user.isSignedIn; else userNotSignedIn"
      >
        <button mat-icon-button (click)="onShowSideMenu()">
          <mat-icon>account_circle</mat-icon>
        </button>
      </div>

      <ng-template #userNotSignedIn>
        <a mat-button class="auth-button" routerLink="/sign-in">
          {{ signInLabel }}
        </a>
        <a mat-stroked-button class="auth-button" routerLink="/sign-up">
          {{ signUpLabel }}
        </a>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-toolbar>

<mat-menu #localeMenu>
  <ng-container
    *ngFor="
      let languageLocale of languageLocales;
      trackBy: trackByLanguageLocale
    "
  >
    <button mat-menu-item (click)="onSetLanguageLocale(languageLocale)">
      {{ languageLocale.language }}
    </button>
  </ng-container>
</mat-menu>
