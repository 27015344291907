import { Injectable } from '@angular/core'
import { CanCreateGameSetupResponse } from '../responses/can-create-game-setup.response'
import { firstValueFrom } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { GameSetupConfigs } from '../../configs/game-setup.configs'
import { CreateGameSetupRequest } from '../requests/create-game-setup.request'
import { CommandApiResponse } from '../../../core/services/models/command-api.response'
import { CreateGameSetupErrorCode } from '../error-codes/error-status-provider/create-game-setup.error-code'
import { FilteredPlayer } from '../models/filtered-player'
import { CreateGameSetupResponse } from '../responses/create-game-setup.response'

@Injectable()
export class CreateGameSetupService {
  constructor(
    private httpClient: HttpClient,
    private gameSetupConfigs: GameSetupConfigs,
  ) {}

  async getPoolPointsOptions(): Promise<number[]> {
    return await firstValueFrom(
      this.httpClient.get<number[]>(
        `${this.gameSetupConfigs.baseUrl}/pool-points-options`,
        { withCredentials: true },
      ),
    )
  }

  async getDoublingCountOptions(): Promise<number[]> {
    return await firstValueFrom(
      this.httpClient.get<number[]>(
        `${this.gameSetupConfigs.baseUrl}/doubling-count-options`,
        { withCredentials: true },
      ),
    )
  }

  async canCreateGameSetup(): Promise<CanCreateGameSetupResponse> {
    return await firstValueFrom(
      this.httpClient.get<CanCreateGameSetupResponse>(
        `${this.gameSetupConfigs.baseUrl}/can-create-game`,
        { withCredentials: true },
      ),
    )
  }

  async createGameSetup(
    createGameSetupRequest: CreateGameSetupRequest,
  ): Promise<CreateGameSetupResponse> {
    const response = await firstValueFrom(
      this.httpClient.post<CommandApiResponse<CreateGameSetupErrorCode>>(
        `${this.gameSetupConfigs.baseUrl}/create-game`,
        createGameSetupRequest,
        { withCredentials: true },
      ),
    )

    return CreateGameSetupResponse.fromApiResponse(response)
  }

  async filterPlayers(usernameFilter: string): Promise<FilteredPlayer[]> {
    return await firstValueFrom(
      this.httpClient.get<FilteredPlayer[]>(
        `${this.gameSetupConfigs.baseUrl}/filter-players`,
        { withCredentials: true, params: { usernameFilter } },
      ),
    )
  }
}
