export abstract class CommandApiResponse<TErrorCode>
{
  protected _errorCode!: TErrorCode
  get errorCode(): TErrorCode {
    return this._errorCode
  }

  protected _errorCodes: TErrorCode[] = []
  get errorCodes(): TErrorCode[] {
    return this._errorCodes
  }

  protected _isSuccessful: boolean = false
  get isSuccessful(): boolean {
    return this._isSuccessful
  }
}
