import { ApplicationConfig } from '@angular/core'
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router'
import { appRoutes } from './app.routes'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideLanguageLocales } from '../core/services/locales/language-locale.provider'
import { provideAppName } from '../core/providers/app-name/app-name.provider'
import { provideLogoAssets } from '../core/providers/logo-assets/logo-assets.provider'
import { provideHttpClient } from '@angular/common/http'
import { provideIdentityConfigs } from '../identity/providers/identity-configs.provider'
import { providePasswordRegEx } from '../identity/providers/password-regex.provider'
import { provideGamePlayConfigs } from '../game-play/configs/game-play-configs.provider'
import { provideGameSetupConfigs } from '../game-setup/configs/game-setup-configs.provider'
import { provideUsernameRegEx } from '../identity/providers/username-regex.provider'
import { provideUsernameNotSetLabel } from '../core/providers/username-not-set-label/username-not-set-label.provider'
import { provideLocaleService } from '../core/services/locales/locale.service.provider'
import { provideMetaTags } from './meta-tags.provider'

export const appProviders: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideAnimations(),
    provideHttpClient(),
    provideLanguageLocales,
    provideAppName,
    provideUsernameNotSetLabel,
    provideLogoAssets,
    provideIdentityConfigs,
    providePasswordRegEx,
    provideUsernameRegEx,
    provideGameSetupConfigs,
    provideGamePlayConfigs,
    provideLocaleService,
    provideMetaTags,
  ],
}
