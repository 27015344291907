import { Inject, Injectable } from '@angular/core'
import { ThemeType } from '../theme/theme.service'
import { LanguageLocale } from '../locales/language-locale'
import { LANGUAGE_LOCALES } from '../locales/language-locale.provider'

@Injectable({ providedIn: 'root' })
export class WindowService {
  constructor(
    @Inject(LANGUAGE_LOCALES) private languageLocales: LanguageLocale[],
  ) {}

  prefersColorScheme(themeType: ThemeType): boolean {
    return window.matchMedia(
      `(prefers-color-scheme: ${themeType.toLowerCase()}`,
    ).matches
  }

  reload(languageLocale: LanguageLocale) {
    const pathnameLocaleStripped = this.languageLocales.reduce(
      (pathname, languageLocale) =>
        pathname.replace(`/${languageLocale.localeId}`, ''),
      window.location.pathname,
    )

    window.location.href = `${window.location.origin}/${languageLocale.localeId}${pathnameLocaleStripped}`
  }

  replace(href: string) {
    window.location.replace(href)
  }

  findQueryParameter(paramKey: string): string | null {
    const queryString = window.location.search
    const searchParams = new URLSearchParams(queryString)
    return searchParams.get(paramKey)
  }
}
