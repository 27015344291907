import { Component, Input } from '@angular/core'
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'

@Component({
  selector: 'sans-password-input',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
  templateUrl: './password-input.component.html',
  styleUrl: './password-input.component.scss',
})
export class PasswordInputComponent {
  @Input() passwordLabel!: string
  @Input() passwordFormControlName!: string
  @Input() formGroup!: FormGroup

  private isPasswordVisible = false
  tooltipShowDelay = 1000

  visibilityIconAriaLabel = this.getVisibilityIconAriaLabel()
  visibilityIconName = this.getVisibilityIconName()
  passwordInputType = this.getPasswordInputType()

  onVisibilityIconSelected(): void {
    this.isPasswordVisible = !this.isPasswordVisible
    this.visibilityIconAriaLabel = this.getVisibilityIconAriaLabel()
    this.visibilityIconName = this.getVisibilityIconName()
    this.passwordInputType = this.getPasswordInputType()
  }

  private getVisibilityIconName(): string {
    if (this.isPasswordVisible) {
      return 'visibility'
    }

    return 'visibility_off'
  }

  private getPasswordInputType(): string {
    if (this.isPasswordVisible) {
      return 'text'
    }

    return 'password'
  }

  private getVisibilityIconAriaLabel(): string {
    if (this.isPasswordVisible) {
      return $localize`:Icon area label|Password visible on sign up page:Visibility on`
    }

    return $localize`:Icon area label|Password not visible on sign up page:Visibility off`
  }
}
