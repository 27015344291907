import { Provider } from '@angular/core'
import { environment } from '../../../environments/environment'
import { GamePlayConfigs } from './game-play.configs'

export const provideGamePlayConfigs: Provider = {
  provide: GamePlayConfigs,
  useFactory: (): GamePlayConfigs => {
    return {
      siteBaseUrl: environment.gamePlaySiteBaseUrl,
      siteApiUrl: environment.gamePlayApiBaseUrl,
      hasGameInProgressPollTimeoutInMilliseconds:
        environment.hasGameInProgressPollTimeoutInMilliseconds,
    }
  },
}
