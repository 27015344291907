import { CommandApiResponse } from '../../../core/services/models/command-api.response'
import { SetUsernameErrorCode } from '../../error-codes/set-username.error-code'
import { CommandApiResponseData } from '../../../core/services/models/command-api.response-data'

export class SetUsernameResponse extends CommandApiResponse<SetUsernameErrorCode> {
  private constructor() {
    super()
  }

  static fromApiResponse(
    commandApiResponseData: CommandApiResponseData<SetUsernameErrorCode>,
  ): SetUsernameResponse {
    const response = new SetUsernameResponse()
    response._errorCode = commandApiResponseData.errorCode
    response._errorCodes = commandApiResponseData.errorCodes
    response._isSuccessful = commandApiResponseData.isSuccessful

    return response
  }

  static withError(errorCode: SetUsernameErrorCode): SetUsernameResponse {
    const response = new SetUsernameResponse()
    response._errorCode = errorCode
    response._errorCodes = [errorCode]
    response._isSuccessful = false

    return response
  }

  getErrorDescriptions(): string[] {
    return this.errorCodes.map((errorCode) =>
      this.getErrorDescription(errorCode),
    )
  }

  private getErrorDescription(errorCode: SetUsernameErrorCode): string {
    switch (errorCode) {
      case 'InvalidUsername':
        return $localize`:Set username error code|Invalid username:Invalid username`
      case 'DuplicateUsername':
        return $localize`:Set username error code|Duplicate username:Username not available`
    }
  }
}
