<ng-container *ngIf="isSuccessful; else signUpNotSuccessfulTitle">
  <h1 mat-dialog-title>{{successfulTitle}}</h1>
</ng-container>

<ng-template #signUpNotSuccessfulTitle>
  <h1 mat-dialog-title>{{notSuccessfulTitle}}</h1>
</ng-template>

<div mat-dialog-content>
  <ng-container *ngIf="!isSuccessful">
    <ul>
      <li *ngFor="let errorDescription of errorDescriptions">
        {{errorDescription}}
      </li>
    </ul>
  </ng-container>
</div>

<div mat-dialog-actions class="dialog-action">
  <button mat-button (click)="onClose()">{{closeLabel}}</button>
</div>
