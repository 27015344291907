<div class="container">
  <h1>
    {{ title }}
  </h1>

  <form
    [formGroup]="createGameSetupForm"
    (ngSubmit)="onCreateGameSetupSubmit()"
  >
    <mat-form-field class="form-field">
      <mat-label>{{ poolPointsLabel }}</mat-label>
      <mat-select formControlName="poolPoints">
        <mat-option
          *ngFor="let poolPointsOption of poolPointsOptions$ | async"
          value="{{ poolPointsOption }}"
          >{{ poolPointsOption }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="form-field">
      <mat-label>{{ doublingCountLabel }}</mat-label>
      <mat-select formControlName="doublingCount">
        <mat-option
          *ngFor="let doublingCountOption of doublingCountOptions$ | async"
          value="{{ doublingCountOption }}"
          >{{ doublingCountOption }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <sans-player-input-field
      class="form-field player-input-field"
      [createGameSetupForm]="createGameSetupForm"
      [playerFormControl]="leftPlayerFormControl"
      playerControlName="leftPlayer"
      sitPosition="Left"
      [otherPlayerFormControl]="rightPlayerFormControl"
      (filteredPlayers)="setLeftPlayerOptions($event)"
    >
    </sans-player-input-field>

    <sans-player-input-field
      class="form-field player-input-field"
      [createGameSetupForm]="createGameSetupForm"
      [playerFormControl]="rightPlayerFormControl"
      playerControlName="rightPlayer"
      sitPosition="Right"
      [otherPlayerFormControl]="leftPlayerFormControl"
      (filteredPlayers)="setRightPlayerOptions($event)"
    >
    </sans-player-input-field>

    <div class="setup-game-buttons">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        class="setup-game-button"
      >
        {{ createGameLabel }}
      </button>

      <button
        mat-raised-button
        color="warn"
        type="button"
        class="setup-game-button"
        (click)="onCancelCreateGameSetup()"
      >
        {{ cancelCreateGameLabel }}
      </button>
    </div>
  </form>
</div>
