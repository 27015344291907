import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { ViewGame } from '../../models/view-game'

@Component({
  selector: 'sans-view-game-info-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatInputModule],
  templateUrl: './view-game-info-dialog.component.html',
  styleUrls: ['./view-game-info-dialog.component.scss'],
})
export class ViewGameInfoDialogComponent {
  title = $localize`:Dialog title|View game info dialog title:Game settings`
  closeButtonLabel = $localize`:Button label|Close dialog button label:Close`

  gameIdLabel = $localize`:Input label|View game info game id input label:Game id`
  createdAtLabel = $localize`:Input label|View game info created at input label:Created at`
  creatorLabel = $localize`:Input label|View game info creator input label:Creator`
  poolPointsLabel = $localize`:Input label|View game info pool points input label:Pool points`
  doublingCountLabel = $localize`:Input label|View game info doubling count input label:Doubling count`
  leftPlayerLabel = $localize`:Input label|View game info left player input label:Left player`
  rightPlayerLabel = $localize`:Input label|View game info right player input label:Right player`

  constructor(@Inject(MAT_DIALOG_DATA) public viewGame: ViewGame) {}
}
