import { Environment } from './environment.config'

export const environment: Environment = {
  name: 'development',
  identityApiBaseUrl: 'https://dev.gamesans.net/api/identity/v1',
  gameSetupApiBaseUrl: 'https://dev.gamesans.net/api/game-setup/v1',
  gamePlaySiteBaseUrl: 'http://localhost:4201',
  gamePlayApiBaseUrl: 'https://dev.gamesans.net/api/game-play/v1',
  hasGameInProgressPollTimeoutInMilliseconds: 10000,
  viewGamesPollTimeoutInMilliseconds: 30000,
}
