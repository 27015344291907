import { CanActivateFn } from '@angular/router'
import { inject } from '@angular/core'
import { CreateGameSetupErrorCodeSetter } from '../error-codes/error-status-provider/create-game-setup-error-code.setter'
import { CreateGameSetupService } from '../services/create-game-setup.service'
import { UserService } from '../../../identity/services/user.service'

export const createGameSetupErrorGuard: CanActivateFn =
  async (): Promise<boolean> => {
    const createGameSetupService = inject(CreateGameSetupService)
    const errorCodeSetter = inject(CreateGameSetupErrorCodeSetter)
    const userService = inject(UserService)

    if (!userService.user.isUsernameSet) {
      errorCodeSetter.setErrorCode('UsernameNotSet')
      return true
    }

    const canCreateGameSetupResponse =
      await createGameSetupService.canCreateGameSetup()

    errorCodeSetter.setErrorCode(canCreateGameSetupResponse.errorCode)
    return !canCreateGameSetupResponse.canCreateGameSetup
  }
