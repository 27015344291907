import { CommandApiResponse } from '../../../core/services/models/command-api.response'
import { SignUpErrorCode } from '../../error-codes/sign-up.error-code'
import { IdentityConfigs } from '../../providers/identity-configs.provider'
import { CommandApiResponseData } from '../../../core/services/models/command-api.response-data'

export class SignUpResponse extends CommandApiResponse<SignUpErrorCode> {
  private constructor(private identityConfigs: IdentityConfigs) {
    super()
  }

  static fromApiResponse(
    commandApiResponseData: CommandApiResponseData<SignUpErrorCode>,
    identityConfigs: IdentityConfigs,
  ): SignUpResponse {
    const response = new SignUpResponse(identityConfigs)
    response._errorCode = commandApiResponseData.errorCode
    response._errorCodes = commandApiResponseData.errorCodes
    response._isSuccessful = commandApiResponseData.isSuccessful

    return response
  }

  getErrorDescriptions(): string[] {
    return this.errorCodes.map((errorCode) =>
      this.getErrorDescription(errorCode),
    )
  }

  private getErrorDescription(errorCode: SignUpErrorCode): string {
    switch (errorCode) {
      case 'DuplicateUsername':
        return $localize`:Sign up error code|Sign up error code get error description:Duplicate username encountered`
      case 'PasswordRequiresDigit':
        return $localize`:Sign up error code|Sign up error code get error description:Password requires at least one digit`
      case 'PasswordRequiresNonAlphanumeric':
        return $localize`:Sign up error code|Sign up error code get error description:Password requires at least one non-alphanumeric character`
      case 'PasswordTooShort':
        return $localize`:Sign up error code|Sign up error code get error description:Password requires at least ${this.identityConfigs.minPasswordLength} characters`
      case 'PasswordRequiresUpper':
        return $localize`:Sign up error code|Sign up error code get error description:Password requires at least one uppercase character`
      case 'InvalidEmail':
        return $localize`:Sign up error code|Sign up error code get error description:Invalid email`
    }
  }
}
