import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { LanguageLocale } from './language-locale'
import { StorageService } from '../storages/storage.service'
import { LANGUAGE_LOCALES } from './language-locale.provider'

@Injectable()
export class LocaleService {
  static readonly LOCALE_ID_STORAGE_KEY = 'LocaleId'
  private _languageLocal?: LanguageLocale

  get languageLocale(): LanguageLocale {
    if (this._languageLocal) {
      return this._languageLocal
    }

    const savedLocaleId =
      this.storageService.getItemStr(
        LocaleService.LOCALE_ID_STORAGE_KEY,
      ) ?? ''

    const savedLanguageLocale = this.languageLocales.find(
      (languageLocale) => languageLocale.localeId === savedLocaleId,
    )

    this._languageLocal = savedLanguageLocale
    return savedLanguageLocale ?? this.languageLocales[0]
  }

  constructor(
    @Inject(LANGUAGE_LOCALES) public languageLocales: LanguageLocale[],
    private storageService: StorageService,
    @Inject(LOCALE_ID) protected appLocaleId: string,
  ) {}

  setLanguageLocale(languageLocale: LanguageLocale): void {
    this.storageService.setItemStr(
      LocaleService.LOCALE_ID_STORAGE_KEY,
      languageLocale.localeId,
    )

    this._languageLocal = languageLocale
  }

  isUserLocaleDifferentFromAppLocale(): boolean {
    return !this.appLocaleId.includes(this.languageLocale.languageId)
  }
}
