import { Provider } from '@angular/core'

export abstract class LogoAssets {
  abstract readonly altImg: string
  abstract readonly imgSrc: string
}

export const provideLogoAssets: Provider = [
  {
    provide: LogoAssets,
    useValue: {
      altImg: $localize`:Logo alt img home:Game sans logo`,
      imgSrc: 'assets/sans-logomark.png',
    },
  },
]
