import { LOCALE_ID, Provider } from '@angular/core'
import { LanguageLocale } from './language-locale'
import { StorageService } from '../storages/storage.service'
import { LANGUAGE_LOCALES } from './language-locale.provider'
import { environment } from '../../../../environments/environment'
import { LocaleService } from './locale.service'
import { LocaleLocalService } from './locale.local.service'

export const provideLocaleService: Provider = [
  {
    provide: LocaleService,
    useFactory: (
      languageLocales: LanguageLocale[],
      storageService: StorageService,
      localeId: string,
    ): LocaleService => {
      if (environment.name === 'local') {
        return new LocaleLocalService(languageLocales, storageService, localeId)
      }
      return new LocaleService(languageLocales, storageService, localeId)
    },
    deps: [LANGUAGE_LOCALES, StorageService, LOCALE_ID],
  },
]
