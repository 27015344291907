import { CommandApiResponse } from '../../core/services/models/command-api.response'
import { CommandApiResponseData } from '../../core/services/models/command-api.response-data'
import { SignInErrorCode } from '../error-codes/sign-in.error-code'

export class SignInResponse extends CommandApiResponse<SignInErrorCode> {
  private constructor() {
    super()
  }

  static fromApiResponse(
    commandApiResponseData: CommandApiResponseData<SignInErrorCode>,
  ): SignInResponse {
    const response = new SignInResponse()
    response._errorCode = commandApiResponseData.errorCode
    response._errorCodes = commandApiResponseData.errorCodes
    response._isSuccessful = commandApiResponseData.isSuccessful

    return response
  }
}
