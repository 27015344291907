import { CreateGameSetupErrorCode } from './create-game-setup.error-code'
import { CreateGameSetupErrorCodeProvider } from './create-game-setup-error-code.provider'

export class CreateGameSetupErrorCodeSetter extends CreateGameSetupErrorCodeProvider {
  private createGameSetupErrorCode!: CreateGameSetupErrorCode

  setErrorCode(errorCode: CreateGameSetupErrorCode) {
    this.createGameSetupErrorCode = errorCode
  }

  getErrorCode(): CreateGameSetupErrorCode {
    return this.createGameSetupErrorCode
  }
}
