import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatTableModule } from '@angular/material/table'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { RouterLink } from '@angular/router'
import { ViewGamePlayerCellComponent } from '../view-game-player-cell/view-game-player-cell.component'
import { TypeSafeMatCellDef } from '../../../../core/directives/type-safe-mat-cell-def.directive'
import { ViewGamesViewModel } from '../../view-models/view-games/view-games.view-model'

@Component({
  selector: 'sans-view-games',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    RouterLink,
    ViewGamePlayerCellComponent,
    TypeSafeMatCellDef,
  ],
  templateUrl: './view-games.component.html',
  styleUrls: ['./view-games.component.scss'],
})
export class ViewGamesComponent {
  viewGameInfoAriaLabel = $localize`:View game info aria label|View games component:View game info`
  gameShortIdColumnHeader = $localize`:Game short id column header|View games component:Game id`
  gameInfoColumnHeader = $localize`:Game info column header|View games component:Info`
  gameCreatorColumnHeader = $localize`:Game creator column header|View games component:Creator`
  leftPlayerColumnHeader = $localize`:Left player column header|View games component:Left player`
  rightPlayerColumnHeader = $localize`:Right player column header|View games component:Right player`
  poolPointsColumnHeader = $localize`:Pool points column header|View games component:Pool points`
  cancelButtonLabel = $localize`:Cancel button label|View games component:Cancel`

  readonly displayedColumns = [
    'gameInfo',
    'gameShortId',
    'creator',
    'leftPlayer',
    'rightPlayer',
    'poolPoints',
    'cancel',
  ]

  constructor(public viewModel: ViewGamesViewModel) {}
}
