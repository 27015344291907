import { Component, Input } from '@angular/core'
import { NgOptimizedImage } from '@angular/common'
import { LogoAssets } from '../../../core/providers/logo-assets/logo-assets.provider'
import { RouterLink } from '@angular/router'

@Component({
  selector: 'sans-identity-template',
  standalone: true,
  imports: [NgOptimizedImage, RouterLink],
  templateUrl: './identity-template.component.html',
  styleUrl: './identity-template.component.scss',
})
export class IdentityTemplateComponent {
  @Input() title!: string
  constructor(public logoAssets: LogoAssets) {}
}
