<mat-sidenav-container class="container" hasBackdrop="true">
  <mat-sidenav
    mode="over"
    [opened]="(isSideMenuOpen$ | async) ?? false"
    position="end"
    (closed)="onSideMenuClosed()"
    autoFocus="false"
    class="side-menu"
  >
    <sans-account-menu></sans-account-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="container-content">
      <div class="main">
        <sans-toolbar></sans-toolbar>
        <router-outlet></router-outlet>
      </div>

      <div>
        <sans-footer></sans-footer>
      </div>

      <lib-sans-web-shared></lib-sans-web-shared>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
