import { CommandApiResponse } from '../../../core/services/models/command-api.response'
import { CommandApiResponseData } from '../../../core/services/models/command-api.response-data'
import { CreateGameSetupErrorCode } from "../error-codes/error-status-provider/create-game-setup.error-code";

export class CreateGameSetupResponse extends CommandApiResponse<CreateGameSetupErrorCode> {
  private constructor() {
    super()
  }

  static fromApiResponse(
    commandApiResponseData: CommandApiResponseData<CreateGameSetupErrorCode>,
  ): CreateGameSetupResponse {
    const response = new CreateGameSetupResponse()
    response._errorCode = commandApiResponseData.errorCode
    response._errorCodes = commandApiResponseData.errorCodes
    response._isSuccessful = commandApiResponseData.isSuccessful

    return response
  }

  static withError(errorCode: CreateGameSetupErrorCode): CreateGameSetupResponse {
    const response = new CreateGameSetupResponse()
    response._errorCode = errorCode
    response._errorCodes = [errorCode]
    response._isSuccessful = false

    return response
  }

  getErrorDescriptions(usernameNotSetLabel: string): string[] {
    return this.errorCodes.map((errorCode) =>
      this.getErrorDescription(errorCode, usernameNotSetLabel),
    )
  }

  private getErrorDescription(errorCode: CreateGameSetupErrorCode, usernameNotSetLabel: string): string {
    switch (errorCode) {
      case 'UserIsCreatorOfPendingWaitingGame':
        return $localize`:Create game setup error code|User is creator of pending waiting game:You must play or cancel your created game`
      case 'UserSatAtPendingWaitingGame':
        return $localize`:Create game setup error code|User sat at pending waiting game:You have already started playing another game`
      case 'BothPlayersAreSame':
        return $localize`:Create game setup error code|Both players are same:Both of the invited players are the same`
      case 'UsernameNotSet':
        return usernameNotSetLabel
    }
  }
}
