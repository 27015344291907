import { UserResponse } from '../responses/user.response'

export class User {
  readonly isUsernameSet = this.username !== ''

  private constructor(
    readonly username: string,
    readonly email: string,
    readonly lastName: string,
    readonly firstName: string,
    readonly isSignedIn: boolean,
  ) {}

  static asSignedIn(userResponse: UserResponse): User {
    return new User(
      userResponse.username,
      userResponse.email,
      userResponse.firstName,
      userResponse.lastName,
      true,
    )
  }

  static asNotSignedIn(): User {
    return new User('', '', '', '', false)
  }
}
