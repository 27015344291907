import { Provider } from '@angular/core'
import { environment } from '../../../environments/environment'

export abstract class IdentityConfigs {
  abstract readonly apiBaseUrl: string
  abstract readonly minPasswordLength: number
}

export const provideIdentityConfigs: Provider = {
  provide: IdentityConfigs,
  useFactory: (): IdentityConfigs => {
    return {
      apiBaseUrl: environment.identityApiBaseUrl,
      minPasswordLength: 8
    }
  },
}
