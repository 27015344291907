import { InjectionToken, Provider } from '@angular/core'

export const USERNAME_REGEX = new InjectionToken<RegExp>('Username regex')

export const provideUsernameRegEx: Provider = [
  {
    provide: USERNAME_REGEX,
    useFactory: (): RegExp => {
      return new RegExp(`^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){2,18}[a-zA-Z0-9]$`)
    },
  },
]
