<ng-container *ngIf="viewModel.showDisplayName">
  {{ player.username }}
</ng-container>

<ng-container *ngIf="viewModel.showInvited$ | async">
  {{ viewModel.invitedLabel }}
</ng-container>

<ng-container *ngIf="viewModel.showSitAtGame">
  <button mat-raised-button color="primary" (click)="viewModel.onSitSelected()">
    {{ viewModel.sitButtonLabel }}
  </button>
</ng-container>

<ng-container *ngIf="viewModel.showLeaveGame">
  <button
    mat-raised-button
    color="primary"
    (click)="viewModel.onLeaveGameSelected()"
  >
    {{ viewModel.leaveGameButtonLabel }}
  </button>
</ng-container>
