import { InjectionToken, Provider } from '@angular/core'
import { MetaDefinition } from '@angular/platform-browser'
import { environment } from '../../environments/environment'

export const META_TAGS = new InjectionToken<MetaDefinition[]>('Meta tags')

export const provideMetaTags: Provider[] = [
  {
    provide: META_TAGS,
    useFactory: (): MetaDefinition[] => {
      const descriptionTag = {
        name: 'description',
        content: 'Learn, discuss and have fun playing online Preferans. ',
      }

      if (environment.name === 'production') {
        return [descriptionTag]
      }

      return [descriptionTag, { name: 'robots', content: 'noindex,nofollow' }]
    },
  },
]
