import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class DocumentService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  addClassToBody(className: string): void {
    this.document.body.classList.add(className)
  }

  removeClassFromBody(className: string): void {
    this.document.body.classList.remove(className)
  }
}
