import { Provider } from '@angular/core'
import { environment } from '../../../environments/environment'
import { GameSetupConfigs } from './game-setup.configs'

export const provideGameSetupConfigs: Provider = {
  provide: GameSetupConfigs,
  useFactory: (): GameSetupConfigs => {
    return {
      baseUrl: environment.gameSetupApiBaseUrl,
      viewGamesPollTimeoutInMilliseconds:
        environment.viewGamesPollTimeoutInMilliseconds,
    }
  },
}
