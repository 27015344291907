import { Component } from '@angular/core';

@Component({
  selector: 'lib-sans-web-shared',
  standalone: true,
  imports: [],
  template: `
    <p>
      sans-web-shared works!
    </p>
  `,
  styles: ``
})
export class SansWebSharedComponent {

}
