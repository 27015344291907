import { Component, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ViewGamePlayer } from '../../models/view-game-player'
import { ViewGamePlayerNull } from '../../models/view-game-player.null'
import { MatButtonModule } from '@angular/material/button'
import { ViewGame } from '../../models/view-game'
import { ViewGameNull } from '../../models/view-game.null'
import { ViewGamePlayerViewModel } from '../../view-models/view-game-player/view-game-player.view-model'

@Component({
  selector: 'sans-view-game-player-cell',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './view-game-player-cell.component.html',
  styleUrls: ['./view-game-player-cell.component.scss'],
  providers: [ViewGamePlayerViewModel],
})
export class ViewGamePlayerCellComponent implements OnInit {
  @Input() player: ViewGamePlayer = new ViewGamePlayerNull('Left', false)
  @Input() viewGame: ViewGame = new ViewGameNull()

  constructor(public viewModel: ViewGamePlayerViewModel) {}

  ngOnInit(): void {
    this.viewModel.onViewInit(this.player,this.viewGame)
  }
}
