import { ThemeService, ThemeType } from '../core/services/theme/theme.service'
import { WindowService } from '../core/services/window/window.service'
import { Inject, Injectable } from '@angular/core'
import { StorageService } from '../core/services/storages/storage.service'
import { UserService } from '../identity/services/user.service'
import { firstValueFrom } from 'rxjs'
import { GamePlayService } from '../game-play/services/game-play.service'
import { GamePlayConfigs } from '../game-play/configs/game-play.configs'
import { LocaleService } from '../core/services/locales/locale.service'
import { Meta, MetaDefinition, Title } from '@angular/platform-browser'
import { META_TAGS } from './meta-tags.provider'

@Injectable()
export class AppComponentViewModel {
  private hasGameInProgressPollingIntervalId = 0
  private _hasPollingForHasGameInProgressStarted = false

  get hasPollingForHasGameInProgressStarted(): boolean {
    return this._hasPollingForHasGameInProgressStarted
  }

  constructor(
    private storageService: StorageService,
    private windowService: WindowService,
    private themeService: ThemeService,
    private localeService: LocaleService,
    private userService: UserService,
    private gamePlayService: GamePlayService,
    private gamePlayConfigs: GamePlayConfigs,
    private metaTagService: Meta,
    @Inject(META_TAGS) private metaTags: MetaDefinition[],
    private title: Title,
  ) {}

  setPageTitle(): void {
    this.title.setTitle($localize`:Page title|Head tag title:Game Sans - Online Preferans`)
  }

  addMetaTags(): void {
    this.metaTagService.addTags(this.metaTags)
  }

  setInitialTheme(): void {
    const theme = this.storageService.getItemStr(
      ThemeService.LOCAL_STORAGE_KEY,
    ) as ThemeType

    if (theme) {
      this.themeService.setTheme(theme)
      return
    }

    const preferredTheme = this.windowService.prefersColorScheme('Light')
      ? 'Light'
      : 'Dark'

    this.themeService.setTheme(preferredTheme)
  }

  setInitialLocale(): void {
    if (this.localeService.isUserLocaleDifferentFromAppLocale()) {
      this.windowService.reload(this.localeService.languageLocale)
    }
  }

  async nextUser(): Promise<void> {
    const isUserFetchedAtLeastOnce = await firstValueFrom(
      this.userService.isUserFetchedAtLeastOnce$,
    )

    if (!isUserFetchedAtLeastOnce) {
      await this.userService.nextUser()
    }
  }

  async startPollingForHasGameInProgress(): Promise<void> {
    this._hasPollingForHasGameInProgressStarted = true
    await this.gamePlayService.hasGameInProgress()

    this.hasGameInProgressPollingIntervalId = setInterval(async () => {
      await this.gamePlayService.hasGameInProgress()
    }, this.gamePlayConfigs.hasGameInProgressPollTimeoutInMilliseconds)
  }

  stopPollingForHasGameInProgress(): void {
    this._hasPollingForHasGameInProgressStarted = false
    clearInterval(this.hasGameInProgressPollingIntervalId)
  }

  handleHasGameInProgress(hasGameInProgress: boolean): void {
    if (hasGameInProgress) {
      this.windowService.replace(this.gamePlayConfigs.siteBaseUrl)
    }
  }
}
