import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'sans-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  message = $localize`:Message|Footer message:I ❤️ preferans`
}
