<div class="container">
  <a routerLink="/" class="logo-img">
    <img
      [ngSrc]="logoAssets.imgSrc"
      [width]="50"
      [height]="50"
      [alt]="logoAssets.altImg"
      priority="high"
    />
  </a>

  <h1>{{ title }}</h1>
  <h4>{{ description }}</h4>

  <button mat-raised-button color="primary" (click)="onViewGamesSelected()">
    {{ viewGamesButtonLabel }}
  </button>
</div>
