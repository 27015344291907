import { Injectable } from '@angular/core'
import { ViewGamesService } from '../../services/view-games/view-games.service'
import { viewGamesFilterPredicate, ViewGamesFilter } from './view-games.filter'
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs'
import { ViewGame } from '../../models/view-game'
import { UserService } from '../../../../identity/services/user.service'
import { ViewGameInfoDialogComponent } from '../../components/view-game-info-dialog/view-game-info-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { GameSetupConfigs } from '../../../configs/game-setup.configs'

@Injectable()
export class ViewGamesViewModel {
  private pollingIntervalId = 0
  private _viewGamesFilter: ViewGamesFilter = 'WaitingGames'

  private readonly _games$: BehaviorSubject<ViewGame[]> = new BehaviorSubject<
    ViewGame[]
  >([])

  readonly games$: Observable<ViewGame[]> = this._games$.asObservable()

  readonly gamesFetched$ = this.userService.userFetchedAtLeastOnce$.pipe(
    switchMap(async (userFetchedAtLeastOnce) => {
      if (userFetchedAtLeastOnce) {
        await this.getGames()
        return true
      }

      return false
    }),
  )

  readonly showUsernameNotSetLabel$ = this.userService.user$.pipe(
    map((user) => user.isSignedIn && !user.isUsernameSet),
  )

  readonly hasGames$ = this.games$.pipe(map((games) => games.length > 0))

  constructor(
    private viewGamesService: ViewGamesService,
    private userService: UserService,
    private dialog: MatDialog,
    private gameSetupConfigs: GameSetupConfigs,
  ) {}

  async onViewInit(): Promise<void> {
    this.pollingIntervalId = setInterval(async () => {
      await this.getGames()
    }, this.gameSetupConfigs.viewGamesPollTimeoutInMilliseconds)
  }

  onViewDestroy(): void {
    clearInterval(this.pollingIntervalId)
  }

  async onRefreshGamesSelected(): Promise<void> {
    await this.getGames()
  }

  async onViewGamesFilterSelected(filter: ViewGamesFilter): Promise<void> {
    this._viewGamesFilter = filter
    await this.getGames()
  }

  async onCancelGame(viewGame: ViewGame): Promise<void> {
    await this.viewGamesService
      .cancelGameSetup(viewGame.gameShortId)
      .finally(async () => {
        await this.getGames()
      })
  }

  onViewGameInfoSelected(viewGame: ViewGame): void {
    this.dialog.open(ViewGameInfoDialogComponent, { data: viewGame })
  }

  async getGames(): Promise<void> {
    const games = this.userService.user.isSignedIn
      ? await this.viewGamesService.getGames()
      : await this.viewGamesService.getGamesAsGuest()

    const gamesFiltered = games.filter(
      viewGamesFilterPredicate(this._viewGamesFilter),
    )

    this._games$.next(gamesFiltered)
  }
}
