import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { Router, RouterLink } from '@angular/router'
import { UserService } from '../../services/user.service'
import { AlreadySignedInComponent } from '../already-signed-in/already-signed-in.component'
import { SignInDialogComponent } from '../sign-in-dialog/sign-in-dialog.component'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { IdentityTemplateComponent } from '../identity-template/identity-template.component'

@Component({
  selector: 'sans-sign-in',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    RouterLink,
    AlreadySignedInComponent,
    MatDialogModule,
    IdentityTemplateComponent,
  ],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  title = $localize`:Title|Sign in title:Sign in to Game Sans`
  submitLabel = $localize`:Submit|Sign in submit label:Sign in`

  emailOrUsernameLabel = $localize`:Label|Email or username label:Email or Username`
  passwordLabel = $localize`:Label|Password label:Password`

  signUpLabel = $localize`:Label|Sign up label on sign in page:Need to create an account?`
  signUpActionLabel = $localize`:Label|Sign up action label on sign in page:Sign up`
  requestResetPasswordLabel = $localize`:Label|Request reset password on sign in page:Forgot password?`

  signInForm = this.formBuilder.group({
    emailOrUsername: ['', [Validators.required]],
    password: ['', Validators.required],
  })

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  async onSubmit(): Promise<void> {
    if (!this.signInForm.valid) {
      return
    }

    const response = await this.userService.signIn(
      this.signInForm.controls.emailOrUsername.value!,
      this.signInForm.controls.password.value!,
    )

    if (response.isSuccessful) {
      await this.router.navigate(['/'])
      return
    }

    this.dialog.open(SignInDialogComponent)
  }
}
