import { Component, OnDestroy, OnInit } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ToolbarComponent } from '../core/components/toolbar/toolbar.component'
import { FooterComponent } from '../core/components/footer/footer.component'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSidenavModule } from '@angular/material/sidenav'
import { SideMenuService } from '../core/services/side-menu/side-menu.service'
import { AccountMenuComponent } from '../identity/components/account-menu/account-menu.component'
import { UserService } from '../identity/services/user.service'
import { GamePlayService } from '../game-play/services/game-play.service'
import { Subscription } from 'rxjs'
import { AppComponentViewModel } from './app.component.view-model'
import { CommonModule } from '@angular/common'
import { SansWebSharedComponent } from '../../../sans-web-shared/src/lib/sans-web-shared.component'
// import { SansWebSharedComponent } from '../../../../dist/sans-web-shared'

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ToolbarComponent,
    FooterComponent,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    AccountMenuComponent,
    SansWebSharedComponent,
  ],
  selector: 'sans-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [GamePlayService, AppComponentViewModel],
})
export class AppComponent implements OnInit, OnDestroy {
  private userSubscription = new Subscription()
  private hasGameInProgressSubscription = new Subscription()

  isSideMenuOpen$ = this.sideMenuService.isOpen$

  constructor(
    private sideMenuService: SideMenuService,
    private userService: UserService,
    private gamePlayService: GamePlayService,
    private viewModel: AppComponentViewModel,
  ) {}

  async ngOnInit(): Promise<void> {
    this.viewModel.setPageTitle()
    this.viewModel.addMetaTags()
    this.viewModel.setInitialTheme()

    this.viewModel.setInitialLocale()
    await this.viewModel.nextUser()

    this.userSubscription = this.userService.user$.subscribe(async (user) => {
      if (!user.isSignedIn) {
        this.viewModel.stopPollingForHasGameInProgress()
        return
      }

      if (this.viewModel.hasPollingForHasGameInProgressStarted) {
        return
      }

      await this.viewModel.startPollingForHasGameInProgress()
    })

    this.hasGameInProgressSubscription =
      this.gamePlayService.hasGameInProgress$.subscribe((hasGameInProgress) =>
        this.viewModel.handleHasGameInProgress(hasGameInProgress),
      )
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe()
    this.hasGameInProgressSubscription.unsubscribe()
    this.viewModel.stopPollingForHasGameInProgress()
  }

  onSideMenuClosed(): void {
    this.sideMenuService.setIsOpen(false)
  }
}
