import { Injectable } from '@angular/core'
import { SitPosition } from '../../types/sit-position'
import { CommandApiResponse } from '../../../../core/services/models/command-api.response'
import { SitAtGameErrorCode } from '../../error-codes/sit-at-game.error-code'
import { firstValueFrom } from 'rxjs'
import { LeaveGameSetupErrorCode } from '../../error-codes/leave-game-setup.error-code'
import { AcceptInviteErrorCode } from '../../error-codes/accept-invite.error-code'
import { HttpClient } from '@angular/common/http'
import { GameSetupConfigs } from '../../../configs/game-setup.configs'

@Injectable()
export class GameSetupPlayerService {
  constructor(
    private httpClient: HttpClient,
    private gameSetupConfigs: GameSetupConfigs,
  ) {}

  async sitAtGame(
    gameShortId: string,
    sitPosition: SitPosition,
  ): Promise<CommandApiResponse<SitAtGameErrorCode>> {
    return await firstValueFrom(
      this.httpClient.post<CommandApiResponse<SitAtGameErrorCode>>(
        `${this.gameSetupConfigs.baseUrl}/sit-at-game`,
        {
          gameShortId,
          sitPosition,
        },
        {
          withCredentials: true,
        },
      ),
    )
  }

  async leaveGame(
    gameShortId: string,
    sitPosition: SitPosition,
  ): Promise<CommandApiResponse<LeaveGameSetupErrorCode>> {
    return await firstValueFrom(
      this.httpClient.post<CommandApiResponse<LeaveGameSetupErrorCode>>(
        `${this.gameSetupConfigs.baseUrl}/leave-game`,
        {
          gameShortId,
          sitPosition,
        },
        {
          withCredentials: true,
        },
      ),
    )
  }

  async acceptInvite(
    gameShortId: string,
    sitPosition: SitPosition,
  ): Promise<CommandApiResponse<AcceptInviteErrorCode>> {
    return await firstValueFrom(
      this.httpClient.post<CommandApiResponse<AcceptInviteErrorCode>>(
        `${this.gameSetupConfigs.baseUrl}/accept-invite`,
        {
          gameShortId,
          sitPosition,
        },
        {
          withCredentials: true,
        },
      ),
    )
  }
}
