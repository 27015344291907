<div class="container">
  <a routerLink="/" class="logo-img">
    <img
      [ngSrc]="logoAssets.imgSrc"
      [width]="50"
      [height]="50"
      [alt]="logoAssets.altImg"
      priority="high"
    />
  </a>

  <p>{{ message }}</p>

  <div class="check-mark">
    <mat-icon aria-hidden="false" fontIcon="checkmark"></mat-icon>
  </div>

  <button
    mat-raised-button
    color="primary"
    (click)="onHomeSelected()"
  >
    {{homeButtonLabel}}
  </button>
</div>
