import { ViewGamePlayerResponse } from '../responses/view-game-player.response'

export class ViewGamePlayer {
  readonly username = this.viewGamePlayerResponse.username
  readonly sitPosition = this.viewGamePlayerResponse.sitPosition
  readonly hasSat = this.viewGamePlayerResponse.hasSat
  readonly isCreator = this.viewGamePlayerResponse.isCreator
  readonly isInvited = this.viewGamePlayerResponse.isInvited
  readonly canUserSitAtGame = this.viewGamePlayerResponse.canUserSitAtGame
  readonly canUserLeaveGame = this.viewGamePlayerResponse.canUserLeaveGame
  readonly canUserAcceptInvite =
    this.viewGamePlayerResponse.canUserAcceptInvite
  readonly isLeftPlayer = this.sitPosition === 'Left'
  readonly isRightPlayer = this.sitPosition === 'Right'

  constructor(private viewGamePlayerResponse: ViewGamePlayerResponse) {}
}
