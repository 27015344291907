import { Component, Inject } from '@angular/core'
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { IdentityTemplateComponent } from '../identity-template/identity-template.component'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { UserService } from '../../services/user.service'
import { Router, RouterLink } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { PASSWORD_REGEX } from '../../providers/password-regex.provider'
import { ResetPasswordDialogComponent } from '../reset-password-dialog/reset-password-dialog.component'
import { CommonModule } from '@angular/common'
import { WindowService } from '../../../core/services/window/window.service'
import { PasswordInputComponent } from '../../../core/components/password-input/password-input.component'

@Component({
  selector: 'sans-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IdentityTemplateComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterLink,
    PasswordInputComponent,
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
  title = $localize`:Title|Reset password title:Reset password`
  emailLabel = $localize`:Label|Email label:Email`
  newPasswordLabel = $localize`:Label|New password label:New password`
  submitLabel = $localize`:Submit|Reset password submit label:Reset password`
  requestPasswordResetLabel = $localize`:Label|Request password reset label:Request password reset`

  email = this.windowService.findQueryParameter('email')

  resetPasswordForm = this.formBuilder.group({
    newPassword: [
      '',
      [Validators.required, Validators.pattern(this.passwordRegEx)],
    ],
  })

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    @Inject(PASSWORD_REGEX) private passwordRegEx: RegExp,
    private windowService: WindowService,
  ) {}

  async onSubmit(): Promise<void> {
    if (!this.resetPasswordForm.valid) {
      return
    }

    const response = await this.userService.resetPassword(
      this.resetPasswordForm.controls.newPassword.value!,
    )

    if (response.isSuccessful) {
      await this.router.navigate(['/sign-in'])
    }

    this.dialog.open(ResetPasswordDialogComponent, { data: response })
  }
}
