<sans-identity-template [title]="title">
  <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()" class="sign-up-form">
    <mat-form-field class="form-field">
      <mat-label>{{ emailLabel }}</mat-label>
      <input matInput type="text" formControlName="email" />
    </mat-form-field>

    <sans-password-input
      [passwordLabel]="passwordLabel"
      [formGroup]="signUpForm"
      passwordFormControlName="password"
      class="form-field"
    ></sans-password-input>

    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="submit-button"
    >
      {{ submitLabel }}
    </button>
  </form>

  <span>
    {{ signInLabel }}
    <a mat-button routerLink="/sign-in">{{ signInActionLabel }}</a>
  </span>
</sans-identity-template>
