<!--suppress JSUnresolvedReference, JSUnusedGlobalSymbols -->
<div class="container" *ngIf="viewModel.games$ | async as games">
  <table mat-table [dataSource]="games" class="mat-elevation-z8">
    <ng-container matColumnDef="gameInfo">
      <th mat-header-cell *matHeaderCellDef>{{ gameInfoColumnHeader }}</th>
      <td mat-cell *matCellDef="let viewGame; dataSource: games">
        <button
          mat-icon-button
          [attr.aria-label]="viewGameInfoAriaLabel"
          (click)="viewModel.onViewGameInfoSelected(viewGame)"
        >
          <mat-icon>info</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="gameShortId">
      <th mat-header-cell *matHeaderCellDef>
        {{ gameShortIdColumnHeader }}
      </th>
      <td mat-cell *matCellDef="let viewGame; dataSource: games">
        {{ viewGame.gameShortId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="creator">
      <th mat-header-cell *matHeaderCellDef>
        {{ gameCreatorColumnHeader }}
      </th>
      <td mat-cell *matCellDef="let viewGame; dataSource: games">
        {{ viewGame.creator.username }}
      </td>
    </ng-container>

    <ng-container matColumnDef="leftPlayer">
      <th mat-header-cell *matHeaderCellDef>
        {{ leftPlayerColumnHeader }}
      </th>
      <td mat-cell *matCellDef="let viewGame; dataSource: games">
        <sans-view-game-player-cell
          [player]="viewGame.leftPlayer"
          [viewGame]="viewGame"
        ></sans-view-game-player-cell>
      </td>
    </ng-container>

    <ng-container matColumnDef="rightPlayer">
      <th mat-header-cell *matHeaderCellDef>
        {{ rightPlayerColumnHeader }}
      </th>
      <td mat-cell *matCellDef="let viewGame; dataSource: games">
        <sans-view-game-player-cell
          [player]="viewGame.rightPlayer"
          [viewGame]="viewGame"
        ></sans-view-game-player-cell>
      </td>
    </ng-container>

    <ng-container matColumnDef="poolPoints">
      <th mat-header-cell *matHeaderCellDef>{{ poolPointsColumnHeader }}</th>
      <td mat-cell *matCellDef="let viewGame; dataSource: games">
        {{ viewGame.gameSettings.poolPoints }}
      </td>
    </ng-container>

    <ng-container matColumnDef="cancel">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let viewGame; dataSource: games">
        <ng-container *ngIf="viewGame.canUserCancel">
          <button
            mat-raised-button
            color="warn"
            (click)="viewModel.onCancelGame(viewGame)"
          >
            {{ cancelButtonLabel }}
          </button>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
