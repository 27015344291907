import { Component } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { Router, RouterLink } from '@angular/router'
import { LogoAssets } from '../../../../core/providers/logo-assets/logo-assets.provider'

@Component({
  selector: 'sans-no-games',
  standalone: true,
  imports: [CommonModule, MatButtonModule, NgOptimizedImage, RouterLink],
  templateUrl: './no-games.component.html',
  styleUrls: ['./no-games.component.scss'],
})
export class NoGamesComponent {
  title = $localize`:Title|No games title:No games currently available`
  createGameButtonLabel = $localize`:Button label|No games create game button label:Create game`

  constructor(
    public logoAssets: LogoAssets,
    private router: Router,
  ) {}

  async onCreateGameSelected(): Promise<void> {
    await this.router.navigate(['/create-game'])
  }
}
