import { CreateGameSetupErrorCode } from '../error-codes/error-status-provider/create-game-setup.error-code'
import { Inject, Injectable } from '@angular/core'
import { USERNAME_NOT_SET_LABEL } from '../../../core/providers/username-not-set-label/username-not-set-label.provider'

@Injectable()
export class CreateGameSetupContent {
  constructor(
    @Inject(USERNAME_NOT_SET_LABEL) private usernameNotSetLabel: string,
  ) {}

  getErrorCodeDescription(
    createGameSetupErrorCode: CreateGameSetupErrorCode,
  ): string {
    if (createGameSetupErrorCode === 'UserIsCreatorOfPendingWaitingGame') {
      return $localize`:getErrorCodeDescription|UserIsCreatorOfPendingWaitingGame:You must play or cancel current game.`
    }

    if (createGameSetupErrorCode === 'UserSatAtPendingWaitingGame') {
      return $localize`:getErrorCodeDescription|UserSatAtPendingWaitingGame:You must leave current game.`
    }

    if (createGameSetupErrorCode === 'BothPlayersAreSame') {
      return $localize`:getErrorCodeDescription|BothPlayersAreSame:Players must be different.`
    }

    if (createGameSetupErrorCode === 'UsernameNotSet') {
      return this.usernameNotSetLabel
    }

    throw new Error(`Unable to ${this.getErrorCodeDescription.name}`)
  }
}
