<h1 mat-dialog-title>{{ title }}</h1>

<div mat-dialog-content>
  <mat-form-field class="form-field">
    <mat-label>{{ gameIdLabel }}</mat-label>
    <input readonly matInput value="{{ viewGame.gameShortId }}" />
  </mat-form-field>

  <mat-form-field class="form-field">
    <mat-label>{{ createdAtLabel }}</mat-label>
    <input
      readonly
      matInput
      value="{{ viewGame.createdAt | date: 'short' }}"
    />
  </mat-form-field>

  <mat-form-field class="form-field">
    <mat-label>{{ creatorLabel }}</mat-label>
    <input readonly matInput value="{{ viewGame.creator.username }}" />
  </mat-form-field>

  <mat-form-field class="form-field">
    <mat-label>{{ poolPointsLabel }}</mat-label>
    <input readonly matInput value="{{ viewGame.gameSettings.poolPoints }}" />
  </mat-form-field>

  <mat-form-field class="form-field">
    <mat-label>{{ doublingCountLabel }}</mat-label>
    <input
      readonly
      matInput
      value="{{ viewGame.gameSettings.doublingCount }}"
    />
  </mat-form-field>

  <mat-form-field class="form-field">
    <mat-label>{{ leftPlayerLabel }}</mat-label>
    <input
      readonly
      matInput
      value="{{ viewGame.leftPlayer.username }}"
    />
  </mat-form-field>

  <mat-form-field class="form-field">
    <mat-label>{{ rightPlayerLabel }}</mat-label>
    <input
      readonly
      matInput
      value="{{ viewGame.rightPlayer.username }}"
    />
  </mat-form-field>
</div>

<div mat-dialog-actions class="dialog-action">
  <button mat-button [mat-dialog-close]="true">{{ closeButtonLabel }}</button>
</div>
