import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatButtonModule } from '@angular/material/button'
import {
  FormBuilder,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { PlayerInputFieldComponent } from '../player-input-field/player-input-field.component'
import { CreateGameSetupService } from '../../services/create-game-setup.service'
import { FilterPlayersErrorProvider } from '../../error-codes/filter-players/filter-players-error.provider'
import { FilteredPlayer } from '../../models/filtered-player'
import { CreateGameSetupDialogComponent } from '../create-game-setup-dialog/create-game-setup-dialog.component'
import { CreateGameSetupResponse } from '../../responses/create-game-setup.response'

@Component({
  selector: 'sans-create-game-setup',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatAutocompleteModule,
    PlayerInputFieldComponent,
  ],
  templateUrl: './create-game-setup.component.html',
  styleUrls: ['./create-game-setup.component.scss'],
  providers: [FilterPlayersErrorProvider],
})
export class CreateGameSetupComponent {
  private leftPlayerOptions: FilteredPlayer[] = []
  private rightPlayerOptions: FilteredPlayer[] = []

  title = $localize`:Page title|Setup game page title:Game settings`
  poolPointsLabel = $localize`:Input label|Pool points input label:Pool points`
  doublingCountLabel = $localize`:Input label|Doubling count input label:Doubling count`

  createGameLabel = $localize`:Button label|Setup game button label:Create game`
  cancelCreateGameLabel = $localize`:Button label|Cancel setup game label:Cancel`

  poolPointsOptions$ = this.createGameSetupService.getPoolPointsOptions()
  doublingCountOptions$ = this.createGameSetupService.getDoublingCountOptions()

  setLeftPlayerOptions(leftPlayerOptions: FilteredPlayer[]) {
    this.leftPlayerOptions = leftPlayerOptions
  }

  setRightPlayerOptions(rightPlayerOptions: FilteredPlayer[]) {
    this.rightPlayerOptions = rightPlayerOptions
  }

  createGameSetupForm = this.formBuilder.group({
    poolPoints: ['', [Validators.required]],
    doublingCount: ['', [Validators.required]],
    leftPlayer: ['', { updateOn: 'change' }],
    rightPlayer: ['', { updateOn: 'change' }],
  })

  leftPlayerFormControl = this.createGameSetupForm.controls.leftPlayer
  rightPlayerFormControl = this.createGameSetupForm.controls.rightPlayer

  constructor(
    private createGameSetupService: CreateGameSetupService,
    private formBuilder: FormBuilder,
    private router: Router,
    private filterPlayersErrorProvider: FilterPlayersErrorProvider,
    private dialog: MatDialog,
  ) {}

  async onCreateGameSetupSubmit(): Promise<void> {
    const leftPlayerInputFieldErrors = this.findPlayerInputFieldErrors(
      this.leftPlayerFormControl.value!,
      this.leftPlayerOptions,
    )

    if (leftPlayerInputFieldErrors) {
      this.leftPlayerFormControl.setErrors(leftPlayerInputFieldErrors)
    }

    const rightPlayerInputFieldErrors = this.findPlayerInputFieldErrors(
      this.rightPlayerFormControl.value!,
      this.rightPlayerOptions,
    )

    if (rightPlayerInputFieldErrors) {
      this.rightPlayerFormControl.setErrors(rightPlayerInputFieldErrors)
    }

    if (!this.createGameSetupForm.valid) {
      return
    }

    if (
      (this.leftPlayerFormControl.value || this.rightPlayerFormControl.value) &&
      this.leftPlayerFormControl.value === this.rightPlayerFormControl.value
    ) {
      this.dialog.open(CreateGameSetupDialogComponent, {
        data: CreateGameSetupResponse.withError('BothPlayersAreSame'),
      })
      return
    }

    const createGameSetupResponse =
      await this.createGameSetupService.createGameSetup({
        gameSettings: {
          poolPoints: Number(
            this.createGameSetupForm.controls.poolPoints.value,
          ),
          doublingCount: Number(
            this.createGameSetupForm.controls.doublingCount.value,
          ),
        },
        leftPlayerUsername: this.leftPlayerFormControl.value!,
        rightPlayerUsername: this.rightPlayerFormControl.value!,
      })

    if (createGameSetupResponse.isSuccessful) {
      await this.router.navigate(['/view-games'])
      return
    }

    this.dialog.open(CreateGameSetupDialogComponent, {
      data: createGameSetupResponse,
    })
  }

  async onCancelCreateGameSetup(): Promise<void> {
    await this.router.navigate(['/'])
  }

  private findPlayerInputFieldErrors(
    playerFormControlValue: string,
    filteredPlayers: FilteredPlayer[],
  ): ValidationErrors | null {
    if (
      playerFormControlValue &&
      !filteredPlayers.find(
        (filteredPlayer) => filteredPlayer.username === playerFormControlValue,
      )
    ) {
      return this.filterPlayersErrorProvider.getValidationError(
        'PlayerUsernameNotFound',
      )
    }

    return null
  }
}
