import { CommandApiResponse } from '../../../core/services/models/command-api.response'
import { RequestPasswordResetErrorCode } from '../../error-codes/request-password-reset.error-code'
import { CommandApiResponseData } from '../../../core/services/models/command-api.response-data'

export class RequestPasswordResetResponse extends CommandApiResponse<RequestPasswordResetErrorCode> {
  private constructor() {
    super()
  }

  static fromApiResponse(
    commandApiResponseData: CommandApiResponseData<RequestPasswordResetErrorCode>,
  ): RequestPasswordResetResponse {
    const response = new RequestPasswordResetResponse()
    response._errorCode = commandApiResponseData.errorCode
    response._errorCodes = commandApiResponseData.errorCodes
    response._isSuccessful = commandApiResponseData.isSuccessful

    return response
  }

  getErrorDescriptions(): string[] {
    return this.errorCodes.map((errorCode) =>
      this.getErrorDescription(errorCode),
    )
  }

  private getErrorDescription(
    errorCode: RequestPasswordResetErrorCode,
  ): string {
    switch (errorCode) {
      case 'EmailNotFound':
        return $localize`:Request password reset error code|Request password reset error code get error description:Email not found`
    }
  }
}
