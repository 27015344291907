import { CommandApiResponse } from '../../../core/services/models/command-api.response'
import { ResetPasswordErrorCode } from '../../error-codes/reset-password.error-code'
import { CommandApiResponseData } from '../../../core/services/models/command-api.response-data'

export class ResetPasswordResponse extends CommandApiResponse<ResetPasswordErrorCode> {
  private constructor() {
    super()
  }

  static fromApiResponse(
    commandApiResponseData: CommandApiResponseData<ResetPasswordErrorCode>,
  ): ResetPasswordResponse {
    const response = new ResetPasswordResponse()
    response._errorCode = commandApiResponseData.errorCode
    response._errorCodes = commandApiResponseData.errorCodes
    response._isSuccessful = commandApiResponseData.isSuccessful

    return response
  }

  getErrorDescriptions(): string[] {
    return this.errorCodes.map((errorCode) =>
      this.getErrorDescription(errorCode),
    )
  }

  private getErrorDescription(errorCode: ResetPasswordErrorCode): string {
    switch (errorCode) {
      case 'UserNotFound':
        return $localize`:Reset password error code|User not found:User not found`
      case 'InvalidToken':
        return $localize`:Reset password error code|Invalid token:Token expired. Request password reset again`
    }
  }
}
