import { Component } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { Router, RouterLink } from '@angular/router'
import { LogoAssets } from '../../../core/providers/logo-assets/logo-assets.provider'
import { MatButtonModule } from '@angular/material/button'

@Component({
  selector: 'sans-already-signed-in',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    NgOptimizedImage,
    RouterLink,
    MatButtonModule,
  ],
  templateUrl: './already-signed-in.component.html',
  styleUrls: ['./already-signed-in.component.scss'],
})
export class AlreadySignedInComponent {
  message = $localize`:Message|Already signed in message:Congrats you're already signed in!`
  homeButtonLabel = $localize`:Button label|Already signed in home button label:Home`

  constructor(
    public logoAssets: LogoAssets,
    private router: Router,
  ) {}

  async onHomeSelected(): Promise<void> {
    await this.router.navigate(['/'])
  }
}
