import { ViewGame } from '../../models/view-game'

export type ViewGamesFilter = 'WaitingGames' | 'AllGames'

export function viewGamesFilterPredicate(
  filter: ViewGamesFilter,
): (value: ViewGame) => boolean {
  if (filter === 'WaitingGames') {
    return (game) => game.viewGameStatus === 'Waiting'
  }

  return (game) =>
    game.viewGameStatus === 'Waiting' || game.viewGameStatus === 'InProgress'
}
